<template>
  <div v-if="assistant">
    <p class="text-center">{{ $t('companyArea.leads.leadOverview.snippets.selectField.necessaryQuestion') }}</p>
    <div class="text-center text-lg font-semibold mt-2">{{ field.label }}</div>
    <div class="flex justify-center flex-wrap mt-2">
      <button v-for="option in options"
              class="btn btn-md m-2"
              :class="!formdata.include.includes(option.id) ? 'btn-green' : 'btn-secondary'"
              @click="includeOption(option)"
      >
        {{
          option.label
        }}
      </button>
    </div>
    <div class="flex justify-center mt-5">
      <button v-if="formdata.include.length > 0 && options.length !== formdata.include.length" class="btn btn-md btn-primary mx-2" @click="syncFilter">{{ $t('general.next') }}</button>
      <button class="btn btn-md btn-primary mx-2" @click="$emit('nextStep')">{{ $t('general.noMatter') }}</button>
    </div>
  </div>
  <div v-else class="flex-col md:flex-row rounded my-4" :class="show ? 'flex' : 'hidden'">
    <div class="relative md:w-1/2 bg-gray-200 pr-2 py-3 pl-3">
      <div class="flex justify-end md:hidden mb-2">
        <info-circle v-if="filters.find(obj => obj.field_id === field.id)"
                     size="xs"
                     color="border-black bg-white"
                     @click="mobile = ! mobile"
        >
          <font-awesome-icon :icon="['fal','filter']" />
        </info-circle>
      </div>
      <div>
        <label class="inline-flex items-center">
          {{ field.label }}
        </label>
      </div>
      <div>
        <!--        <p v-if="filters.find(obj => obj.field_id === field.id)" class="text-sm font-semibold mb-2 text-gray-500">Möchte ich:</p>-->
        <select ref="field"
                v-model="selected"
                :required="show && !!field.required"
                class="mainform-select"
                :name="'selectField' + field.id"
                @change="showHideTrigger()"
        >
          <option v-for="option in options" :value="option.id"><span v-if="optionsFiltered.find(obj => obj.id === option.id)">- </span>{{ option.label }}</option>
        </select>
      </div>
      <div>
        <button class="btn btn-primary btn-sm mt-2" @click="prepareOptions(); showSelection = true">{{ $t('companyArea.leads.leadOverview.snippets.selectField.createFilter') }}</button>
      </div>
    </div>
    <div v-if="filters.find(obj => obj.field_id === field.id)" class="md:block md:w-1/2 bg-green-200 pl-3 md:pl-2 py-3 pr-3" :class="[mobile ? 'block' : 'hidden']">
      <div>
        <p class="text-sm font-semibold mb-2 text-gray-500">{{ $t('companyArea.leads.leadOverview.snippets.selectField.thisOptionWithPlural', optionsNotFiltered.length) }} {{ $t('companyArea.settings.snippets.leadFilters.mustBeSelected') }}:</p>
        <select ref="field"
                v-model="selectedFilter"
                :required="show && !!field.required"
                class="mainform-select"
                :name="'selectField' + field.id"
                @change="showHideTrigger()"
        >
          <option v-for="option in optionsNotFiltered" :id="option.id" :value="option.id">{{ option.label }}</option>
        </select>
      </div>
      <div>
        <button class="btn btn-primary btn-sm mt-2" @click="$emit('delete-filter', currentFilter)">{{ $t('general.removeFilter') }}</button>
      </div>
    </div>

    <confirmation-modal v-if="showSelection" @close="showSelection = false">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.leads.leadOverview.snippets.selectField.whichChoice') }}</div>
      <div class="flex justify-center flex-wrap mt-3">
        <button v-for="option in options"
                class="btn btn-md m-2"
                :class="!formdata.include.includes(option.id) ? 'btn-green' : 'btn-secondary'"
                @click="includeOption(option)"
        >
          {{
            option.label
          }}
        </button>
      </div>
      <div v-if="formdata.include.length === 0" class="text-center text-red-700 my-3">{{ $t('companyArea.leads.leadOverview.snippets.selectField.noFilterNeeded') }}</div>
      <div v-else-if="formdata.include.length !== options.length" class="flex justify-center mt-3">
        <button class="btn btn-md btn-primary mx-2" @click="syncFilter">{{ $t('general.save') }}</button>
      </div>
    </confirmation-modal>
  </div>
</template>

<script>

import { formcreatorMixin } from '../../../../plugins/mixin';
import InfoCircle from '../../../snippets/InfoCircle.vue';
import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';

export default {
  name: 'SelectField',
  // This view is not logic at first. See explanation in LeadFilterController

  components: { ConfirmationModal, InfoCircle },
  mixins: [formcreatorMixin],
  props: {
    assistant: {
      type: Boolean,
      default: false,
    },
    field: Object,
    optionFields: Array,
    formdata: {},
    validation: Object,
    isVisible: {
      type: Boolean,
      default: true,
    },
    filters: Array,
  },
  emits: ['syncFilter', 'nextStep', 'delete-filter'],
  data() {
    return {
      show: false,
      selected: '',
      selectedFilter: '',
      options: [],
      mobile: false,
      showSelection: false,
    };
  },
  computed: {
    canBeFiltered() {
      if(this.options.find(obj => obj.id === this.selected && obj.parent === null)) {
        return false;
      } else if(this.filters.find(obj => obj.field_id === this.field.id && obj.field_value.toString() === this.selected.toString())) {
        return false;
      } else if(this.filters.filter(obj => obj.field_id === this.field.id).length + 1 === this.options.filter(obj => obj.parent !== null).length) {
        return false;
      } else {
        return true;
      }
    },
    optionsFiltered() {
      let options = [];
      for(let i = 0; i < this.options.length; i++) {
        if(this.filters.find(obj => obj.field_id === this.field.id && obj.field_value.toString() === this.options[i].id.toString())) {
          options.push(this.options[i]);
        }
      }
      return options;
    },
    optionsNotFiltered() {
      let options = [];
      for(let i = 0; i < this.options.length; i++) {
        if(! this.filters.find(obj => obj.field_id === this.field.id && obj.field_value.toString() === this.options[i].id.toString())) {
          options.push(this.options[i]);
        } else if(this.options[i].parent === null) {
          options.push(this.options[i]);
        }
      }
      return options;
    },
    currentFilter() {
      return this.filters.find(obj => obj.field_id === this.field.id);
    },
  },
  watch: {
    selected(newVal) {
      this.formdata.dynamic_form[this.field.id] = newVal;
    },
    filters: {
      immediate: true,
      handler() {
        this.getOptions();
        this.getSelected();
        this.getSelectedFilter();
      }, deep: true,
    },
    field: {
      handler() {
        this.getOptions();
        this.prepareOptions()
      }, deep: true,
    },

    formdata: {
      immediate: true,
      handler() {
        this.getIfShow();
      }, deep: true,
    },
  },
  created() {
    this.getSelected();
    this.getSelectedFilter();
    this.getOptions();
    this.prepareOptions();
  },
  methods: {
    getOptions: function() {
      this.options = this.optionFields.filter(value => value.parent === this.field.id);
      this.options.sort(function(a, b) {
        if(a.sort_form > b.sort_form) { return 1; }
        if(a.sort_form < b.sort_form) { return -1; }
        return 0;
      });
    },
    includeOption(option) {
      if(this.formdata.include.includes(option.id)) {
        this.formdata.include = this.formdata.include.filter(item => item !== option.id);
      } else {
        this.formdata.include.push(option.id);
      }
    },
    syncFilter() {
      this.formdata.field_id = this.field.id;
      this.$emit('syncFilter');
      this.showSelection = false;
    },
    getSelectedFilter() {
      if(this.optionsNotFiltered.length > 0) {
        this.selectedFilter = this.optionsNotFiltered[0].id;
      }
    },
    getSelected() {
      this.selected = this.options[0].id;
    },
    prepareOptions() {
      this.formdata.include = [];
      this.options.forEach(item => this.formdata.include.push(item.id));
      if(this.optionsFiltered.length > 0) {
        this.optionsNotFiltered.forEach(obj => this.formdata.include = this.formdata.include.filter(item => item !== obj.id));
      }
    },
  },
};
</script>

