<template>
  <div v-if="register" class="w-96 max-w-full flex flex-col justify-center">
    <input v-model="currentLocation.radius" class="w-full text-center border border-gray-400 mt-5" type="number">
    <p class="text-center mt-3">{{ $t('companyArea.components.map.radiusText1') }} <br>{{ $t('companyArea.components.map.radiusText2') }}</p>
    <div v-if="currentRadius > 0 && unsaved" class="flex justify-center mt-3 mb-3">
      <button :class="{ 'pointer-events-none':(!readyForSave)}" class="btn btn-lg btn-primary" @click="storeArea('all')">
        {{ $t('general.next') }}
      </button>
    </div>
  </div>

  <div v-if="unsaved && !register" class="flex justify-center mt-3">
    <button :class="{ 'pointer-events-none':(!readyForSave)}" class="btn btn-lg btn-primary" @click="submitChanges">
      {{ $t('companyArea.components.map.saveChangesForClassification', { name: classification.name }) }}
    </button>
  </div>

  <div v-if="unsaved && !register" class="text-red-700 my-2"><span class="font-bold">{{ $t('general.attention') }}</span> {{ $t('companyArea.components.map.unsavedChanges') }}</div>
  <div id="map" class="relative w-full" style="height: 500px;">
    <spinner v-if="!loaded || reload"
             ref="reload"
             class="opacity-50"
             style="z-index: 10000;"
    />
  </div>
  <div class="grid place-content-end">
    <div class="basis-1">
      <div class="w-full">
        <div class="flex justify-between">
          <div class="text-xs mt-1">{{ $t('companyArea.components.map.unprecise') }}</div>
          <div class="text-xs mt-1">{{ $t('companyArea.components.map.precise') }}</div>
        </div>
      </div>
      <input v-model="precision"
             class="rounded-lg appearance-none bg-gray-200 h-1 w-full mt-0"
             data-cy="input-range-local"
             max="3"
             min="0"
             step="1"
             type="range"
      >
    </div>
  </div>
  <div v-if="unsaved && !register" class="flex justify-center mt-3">
    <button :class="{ 'pointer-events-none':(!readyForSave)}" class="btn btn-lg btn-primary" @click="submitChanges">
      {{ $t('companyArea.components.map.saveChangesForClassification', { name: classification.name }) }}
    </button>
  </div>
  <p v-if="!register" class="text-xs text-gray-500 mt-4">{{ $t('companyArea.components.map.postcodesInfo') }}</p>
  <div v-if="!register" class="mt-4 border rounded-md p-2">
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(10))}" class="p-2 cursor-pointer" @click="recalculateArea(10)">10</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(11))}" class="p-2 cursor-pointer" @click="recalculateArea(11)">11</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(12))}" class="p-2 cursor-pointer" @click="recalculateArea(12)">12</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(13))}" class="p-2 cursor-pointer" @click="recalculateArea(13)">13</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(14))}" class="p-2 cursor-pointer" @click="recalculateArea(14)">14</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(15))}" class="p-2 cursor-pointer" @click="recalculateArea(15)">15</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(16))}" class="p-2 cursor-pointer" @click="recalculateArea(16)">16</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(17))}" class="p-2 cursor-pointer" @click="recalculateArea(17)">17</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(18))}" class="p-2 cursor-pointer" @click="recalculateArea(18)">18</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(19))}" class="p-2 cursor-pointer" @click="recalculateArea(19)">19</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(20))}" class="p-2 cursor-pointer" @click="recalculateArea(20)">20</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(21))}" class="p-2 cursor-pointer" @click="recalculateArea(21)">21</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(22))}" class="p-2 cursor-pointer" @click="recalculateArea(22)">22</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(23))}" class="p-2 cursor-pointer" @click="recalculateArea(23)">23</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(24))}" class="p-2 cursor-pointer" @click="recalculateArea(24)">24</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(25))}" class="p-2 cursor-pointer" @click="recalculateArea(25)">25</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(26))}" class="p-2 cursor-pointer" @click="recalculateArea(26)">26</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(27))}" class="p-2 cursor-pointer" @click="recalculateArea(27)">27</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(28))}" class="p-2 cursor-pointer" @click="recalculateArea(28)">28</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(29))}" class="p-2 cursor-pointer" @click="recalculateArea(29)">29</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(30))}" class="p-2 cursor-pointer" @click="recalculateArea(30)">30</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(31))}" class="p-2 cursor-pointer" @click="recalculateArea(31)">31</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(32))}" class="p-2 cursor-pointer" @click="recalculateArea(32)">32</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(33))}" class="p-2 cursor-pointer" @click="recalculateArea(33)">33</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(34))}" class="p-2 cursor-pointer" @click="recalculateArea(34)">34</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(35))}" class="p-2 cursor-pointer" @click="recalculateArea(35)">35</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(36))}" class="p-2 cursor-pointer" @click="recalculateArea(36)">36</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(37))}" class="p-2 cursor-pointer" @click="recalculateArea(37)">37</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(38))}" class="p-2 cursor-pointer" @click="recalculateArea(38)">38</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(39))}" class="p-2 cursor-pointer" @click="recalculateArea(39)">39</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(40))}" class="p-2 cursor-pointer" @click="recalculateArea(40)">40</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(41))}" class="p-2 cursor-pointer" @click="recalculateArea(41)">41</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(42))}" class="p-2 cursor-pointer" @click="recalculateArea(42)">42</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(43))}" class="p-2 cursor-pointer" @click="recalculateArea(43)">43</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(44))}" class="p-2 cursor-pointer" @click="recalculateArea(44)">44</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(45))}" class="p-2 cursor-pointer" @click="recalculateArea(45)">45</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(46))}" class="p-2 cursor-pointer" @click="recalculateArea(46)">46</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(47))}" class="p-2 cursor-pointer" @click="recalculateArea(47)">47</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(48))}" class="p-2 cursor-pointer" @click="recalculateArea(48)">48</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(49))}" class="p-2 cursor-pointer" @click="recalculateArea(49)">49</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(50))}" class="p-2 cursor-pointer" @click="recalculateArea(50)">50</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(51))}" class="p-2 cursor-pointer" @click="recalculateArea(51)">51</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(52))}" class="p-2 cursor-pointer" @click="recalculateArea(52)">52</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(53))}" class="p-2 cursor-pointer" @click="recalculateArea(53)">53</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(54))}" class="p-2 cursor-pointer" @click="recalculateArea(54)">54</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(55))}" class="p-2 cursor-pointer" @click="recalculateArea(55)">55</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(56))}" class="p-2 cursor-pointer" @click="recalculateArea(56)">56</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(57))}" class="p-2 cursor-pointer" @click="recalculateArea(57)">57</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(60))}" class="p-2 cursor-pointer" @click="recalculateArea(60)">60</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(61))}" class="p-2 cursor-pointer" @click="recalculateArea(61)">61</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(62))}" class="p-2 cursor-pointer" @click="recalculateArea(62)">62</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(63))}" class="p-2 cursor-pointer" @click="recalculateArea(63)">63</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(64))}" class="p-2 cursor-pointer" @click="recalculateArea(64)">64</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(65))}" class="p-2 cursor-pointer" @click="recalculateArea(65)">65</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(66))}" class="p-2 cursor-pointer" @click="recalculateArea(66)">66</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(67))}" class="p-2 cursor-pointer" @click="recalculateArea(67)">67</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(68))}" class="p-2 cursor-pointer" @click="recalculateArea(68)">68</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(69))}" class="p-2 cursor-pointer" @click="recalculateArea(69)">69</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(70))}" class="p-2 cursor-pointer" @click="recalculateArea(70)">70</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(71))}" class="p-2 cursor-pointer" @click="recalculateArea(71)">71</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(72))}" class="p-2 cursor-pointer" @click="recalculateArea(72)">72</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(73))}" class="p-2 cursor-pointer" @click="recalculateArea(73)">73</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(74))}" class="p-2 cursor-pointer" @click="recalculateArea(74)">74</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(75))}" class="p-2 cursor-pointer" @click="recalculateArea(75)">75</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(76))}" class="p-2 cursor-pointer" @click="recalculateArea(76)">76</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(77))}" class="p-2 cursor-pointer" @click="recalculateArea(77)">77</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(80))}" class="p-2 cursor-pointer" @click="recalculateArea(80)">80</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(81))}" class="p-2 cursor-pointer" @click="recalculateArea(81)">81</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(82))}" class="p-2 cursor-pointer" @click="recalculateArea(82)">82</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(83))}" class="p-2 cursor-pointer" @click="recalculateArea(83)">83</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(84))}" class="p-2 cursor-pointer" @click="recalculateArea(84)">84</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(85))}" class="p-2 cursor-pointer" @click="recalculateArea(85)">85</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(86))}" class="p-2 cursor-pointer" @click="recalculateArea(86)">86</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(87))}" class="p-2 cursor-pointer" @click="recalculateArea(87)">87</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(88))}" class="p-2 cursor-pointer" @click="recalculateArea(88)">88</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(89))}" class="p-2 cursor-pointer" @click="recalculateArea(89)">89</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip.includes(90))}" class="p-2 cursor-pointer" @click="recalculateArea(90)">90</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(91))}" class="p-2 cursor-pointer" @click="recalculateArea(91)">91</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(92))}" class="p-2 cursor-pointer" @click="recalculateArea(92)">92</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(93))}" class="p-2 cursor-pointer" @click="recalculateArea(93)">93</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(94))}" class="p-2 cursor-pointer" @click="recalculateArea(94)">94</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(95))}" class="p-2 cursor-pointer" @click="recalculateArea(95)">95</div>
      <div :class="{ 'bg-green-200':(activeZip.includes(96))}" class="p-2 cursor-pointer" @click="recalculateArea(96)">96</div>
    </div>
  </div>
  <confirmation-modal v-if="modal" style="z-index: 1000;" @close="modal = false">
    <div class="text-center text-gray-900 text-lg font-bold">
      {{ $t('companyArea.components.map.changeForAllClassificationsQuestion', { name: classification.name }) }}
    </div>
    <div class="flex mt-4 justify-center">
      <div class="px-3 py-2 border rounded-md mr-3 mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer"
           @click="storeArea('only')"
      >
        {{ $t('companyArea.components.map.onlyClassification', { name: classification.name }) }}
      </div>
      <div class="px-3 py-2 border rounded-md mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer"
           @click="storeArea('all')"
      >
        {{ $t('companyArea.components.map.forAll') }}
      </div>
    </div>
    <div v-if="classifications.some(obj => obj.radius !== null && obj.id !== classification.id)">
      <div class="flex mt-2 justify-center">
        <div class="px-3 py-2 border rounded-md mb-2 bg-gray-100 hover:bg-gray-300 cursor-pointer"
             @click="storeArea('standard')"
        >
          {{ $t('companyArea.components.map.forAllExcept') }}
        </div>
      </div>
      <div class="flex justify-center">
        <div v-for="specialClassification in classifications.filter(obj => obj.radius !== null && obj.id !== classification.id)"
             :key="specialClassification.id"
             class=" text-xs px-2 py-1 border rounded-md mb-2 bg-gray-300"
        >
          {{ specialClassification.name }}
        </div>
      </div>
    </div>
  </confirmation-modal>
  <spinner v-if="waitForServerResponse" style="z-index: 10000;" />
</template>

<script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import 'leaflet/dist/leaflet.js';
  import companyApi from '../../connections/company';
  import locationApi from '../../connections/location';
  import Spinner from '../../snippets/Spinner.vue';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import store from '../../store/mainStore';
  import markerIcon from 'leaflet/dist/images/marker-icon.png';
  import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
  import markerShadow from 'leaflet/dist/images/marker-shadow.png';
  import notification from '../../connections/notification';

  export default {
    name: 'Map',
    components: { ConfirmationModal, Spinner },
    props: {
      register: {
        type: Boolean,
        default: false,
      },
      locations: Array,
      classification: Object,
      classifications: Array,
      company: Object,
    },
    emits: ['saved'],
    data() {
      return {
        zipPostcodes: [],
        radiusPostcodes: [],
        companyPostcodes: {},
        postcodes: [],
        geoJson: [],
        map: null,
        layer: {},
        radius: 10,
        locationsRadius: [],
        activeZip: this.classification.zip ? this.classification.zip : [],
        currentLocation: {},
        loaded: false,
        reload: false,
        waitForServerResponse: false,
        readyForSave: true,
        unsaved: false,
        modal: false,
        precision: this.getInitialPrecision(),
        formdata: {
          radius: [],
        },
        popup: {},
      };
    },
    computed: {
      currentRadius() {
        return this.formdata.radius.length > 0 ? this.formdata.radius.find(item => item.id === this.currentLocation.id).radius : null;
      },
      saveBtnText() {
        return this.$t('general.show');
      },
    },
    watch: {
      choroplethData: {
        handler() {
          this.updateChoroplethData();
        },
        deep: true,
      },
      geoJson: {
        handler(newValue, oldValue) {
          if (!this.loaded && this.geoJson.length > 0) {
            this.map?.remove();
            this.initMap();
            this.locations.forEach(obj => [this.drawCircle(obj), this.drawMarker(obj)]);
          }
        },
        deep: false,
      },
      currentLocation: {
        handler() {
          if (this.register) {
            this.formdata.radius = [{ id: this.currentLocation.id, radius: this.currentLocation.radius }];
          }
        },
        deep: true,
      },
      precision: {
        async handler(newValue, oldValue) {
          await this.getGeoJson();
          this.zipPostcodes = [];
          this.radiusPostcodes = [];
          this.companyPostcodes = {};
          this.loaded = false;
          this.map?.remove();
          this.checkSpecificAreaSettings();
          await this.initMap();
          this.locations.forEach(obj => [this.drawCircle(obj), this.drawMarker(obj)]);
          console.log('precision done');
        },
      },
      currentRadius() {
        console.log('currentRadius');
        if (this.loaded) {
          this.drawCircle(this.currentLocation);
          if (this.register) {
            this.reload = true;
            this.readyForSave = false;
            const asyncWrapper = async () => {
              try {
                let result = await this.recalculateArea(null);
                if (result === true) {
                  this.reload = false;
                  this.readyForSave = true;
                }
              } catch (error) {
              }
            };
            asyncWrapper();
          }
        }
      },
      classification(newValue, oldValue) {
        this.precision = this.getInitialPrecision();
        this.zipPostcodes = [];
        this.radiusPostcodes = [];
        this.companyPostcodes = {};
        this.unsaved = false;
        this.loaded = false;
        this.popup._source.closePopup();
        this.map?.remove();
        this.checkSpecificAreaSettings();
        this.initMap();
        this.locations.forEach(obj => [this.drawCircle(obj), this.drawMarker(obj)]);
      },
    },
    created() {
      this.currentLocation = this.locations.find(item => item.headquarter === 1);
      this.getPostcodesAndGeoJson();
      this.checkSpecificAreaSettings();
    },
    methods: {
      getInitialPrecision() {
        let radiusSum = 0;
        let zipSum = 0;

        if (!this.classification || !this.classification.separate_area) {
          radiusSum = this.locations.reduce((acc, val) => acc + val?.radius, 0);
          zipSum = this.locations.reduce((acc, val) => acc + ((val.zip?.length ?? 0) * 3), 0);
        } else {
          radiusSum = (this.classification.radius) ? (this.classification.radius?.reduce((acc, val) => acc + val?.radius, 0)) : 0;
          zipSum = (this.classification.zip) ? (this.classification.zip.length * 3) : 0;
        }
        let total = parseInt(radiusSum) + parseInt(zipSum);

        return total > 100 ? 0 : (total > 60 || total === 0 ? 1 : (total > 40 ? 2 : 3));
      },
      getPostcodesAndGeoJson() {
        locationApi.get('postcodes-and-geojson?precision=' + this.precision).then(response => [this.postcodes = response.data[0], this.geoJson = response.data[1]]);
      },
      async getGeoJson() {
        try {
          const response = await locationApi.get('geojson?precision=' + this.precision);
          this.geoJson = response.data[0];
        } catch (error) {
          console.error('Error fetching geoJson:', error);
        }
      },
      submitChanges() {
        if (this.classifications.length > 1) {
          this.modal = true;
        } else {
          this.storeArea('all');
        }
      },
      storeArea(forAllClassifications) {
        this.waitForServerResponse = true;
        // Damit das Backend mit companyPostcodes umgehen kann, wird es in ein Array umgewandelt
        const companyPostcodes = Object.keys(this.companyPostcodes).map(key => [this.companyPostcodes[key]]);
        this.formdata.companyPostcodes = companyPostcodes;
        this.formdata.activeZip = this.activeZip;
        this.formdata.classification = this.classification;
        this.formdata.forAllClassifications = forAllClassifications;

        companyApi.post('companies/' + this.company.id + '/postcodes/sync', this.formdata, { 'axios-retry': { retries: 0 } })
          .then(response => response.data.notificationType === 'success'
            ? [store.commit('company/setInitialCompanyData', response.data.content.company), this.unsaved = false, this.$emit('saved')]
            : [this.notifyValidationError(response.data.message)])
          .catch(error => this.notifyValidationError(error.response.data.message))
          .finally(() => [this.modal = false, this.waitForServerResponse = false]);
      },
      notifyValidationError(validationMessage) {
        try {
          notification['error'](validationMessage.replace(/Radius\.0\.radius/g, 'Radius'));
        } catch (error) {
          notification['error']('Es ist ein Fehler aufgetreten');
        }
      },
      async initMap() {
        console.log('initMap');
        // Create new Leaflet-Map
        this.map = L.map('map').setView([46.8, 8.23], 8);

        // Fügen Sie eine OpenStreetMap-Kachelkarte hinzu
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors',
        }).addTo(this.map);

        this.zipPostcodes = this.postcodes.filter(postalCode =>
          this.activeZip.some(button => postalCode.zip.startsWith(button)),
        );
        let result = await this.recalculateArea(null, true);
        if (result) {
          this.loaded = true;
          this.readyForSave = true;
        }
        return Promise.resolve();

      },
      drawMarker(location) {

        let currentPostcode = this.postcodes.find(obj => obj.id === location.postcode_id);

        const closePopupOnSaveClickHandler = () => {
          console.log('closePopupOnSaveClickHandler');
          this.popup._source.closePopup();
        };

        let debounceTimeout = null;
        const inputChangeRadiusHandler = (event) => {
          console.log('START inputChangeRadiusHandler: event.target.value: ' + event.target.value + ' - currentRadius: ' + this.currentRadius + ' formdata.radius: ' + this.formdata.radius.find(item => item.id === this.currentLocation.id).radius);
          this.formdata.radius.find(item => item.id === this.currentLocation.id).radius = event.target.value;
          console.log('inputChangeRadiusHandler: new Radius: ' + this.formdata.radius.find(item => item.id === this.currentLocation.id).radius);
          console.log('AFTER inputChangeRadiusHandler: event.target.value: ' + event.target.value + ' - currentRadius: ' + this.currentRadius + ' formdata.radius: ' + this.formdata.radius.find(item => item.id === this.currentLocation.id).radius);
        };

        const markerIconDefault = L.icon({
          iconUrl: markerIcon,
          iconRetinaUrl: markerIcon2x,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          tooltipAnchor: [16, -28],
          shadowUrl: markerShadow,
          shadowSize: [41, 41],
          shadowAnchor: [12, 41],
        });

        // Zeichnen des Leaflet Circle-Layers mit dem Mittelpunkt und dem Radius
        const marker = L.marker([currentPostcode.latitude, currentPostcode.longitude], { icon: markerIconDefault }).addTo(this.map);
        const width = 90 + (this.saveBtnText.length * 7);
        this.popup = L.popup().setContent(`
      <div style="width: ${width}px; max-width:200px">
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <input type="text" name="radius" id="inputField` + location.id + `" class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <span class="text-gray-500 sm:text-sm" id="price-currency">Km</span>
            </div>
          </div>
          <button type="button" id="btn` + location.id + `" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          ${this.saveBtnText}
          </button>
        </div>
      </div>
    `);
        if (!this.register) {
          marker.bindPopup(this.popup, {
            minWidth: 100,
            maxWidth: 300,
            closeButton: true,
          });


          marker.on('popupopen', () => {
            this.currentLocation = location;
            document.addEventListener('keydown', closePopupWithEnter);

            this.$nextTick(() => {
              const inputField = document.getElementById('inputField' + location.id);
              inputField.value = this.currentRadius;
              if (isNaN(this.currentRadius)) {
                notification['error'](this.$t('companyArea.components.map.radiusErrorNotification'));
              } else {
                this.radius = this.currentRadius;
              }
              inputField.addEventListener('input', inputChangeRadiusHandler);

              const saveButton = document.getElementById('btn' + location.id);
              saveButton.addEventListener('click', closePopupOnSaveClickHandler);
            });
          });

          marker.on('popupclose', () => {
            document.removeEventListener('keydown', closePopupWithEnter);
            const saveButton = document.getElementById('btn' + location.id);
            saveButton.removeEventListener('click', closePopupOnSaveClickHandler);
            const inputField = document.getElementById('inputField' + location.id);
            inputField.removeEventListener('input', closePopupOnSaveClickHandler);
            if (this.radius !== this.currentRadius) {
              console.log('The radius DID  change, recalculateArea now');
              this.reload = true;
              this.readyForSave = false;
              const asyncWrapper = async () => {
                try {
                  console.log('await this.recalculateArea(null);');
                  let result = await this.recalculateArea(null);
                  if (result === true) {
                    this.reload = false;
                    this.readyForSave = true;
                  }
                } catch (error) {
                  console.log('Catched error in marker.on(popupclose)', error);
                }
              };
              asyncWrapper();
            } else {
              console.log('The radius did NOT change, no recalculateArea. this.radius = ' + this.radius + ' and this.currentRadius = ' + this.currentRadius);
            }
          });

          function closePopupWithEnter(e) {
            if (e.keyCode === 13) {
              this.popup._source.closePopup();
            }
            console.log('closePopupOnEnterHandler');
          }
        }
      },
      drawCircle(location) {
        if (location.hasOwnProperty('mapCircle')) {
          location.mapCircle.removeFrom(this.map); // Entfernen des alten Layers von der Karte
        } else {
          location.mapCircle = null;
        }
        // Berechnung des Radius in Metern (Leaflet verwendet Meter als Einheit)
        const radiusMeters = this.formdata.radius.find(item => item.id === location.id).radius * 1000;
        let currentPostcode = this.postcodes.find(obj => obj.id === location.postcode_id);

        if (isNaN(radiusMeters)) {
          notification['error'](this.$t('companyArea.components.map.radiusErrorNotification'));
          return;
        }

        location.mapCircle = L.circle([currentPostcode.latitude, currentPostcode.longitude], {
          radius: radiusMeters,
          color: 'grey', // Farbe des Kreises
          weight: 1,
          fillColor: 'grey', // Füllfarbe des Kreises
          fillOpacity: 0.2, // Fülltransparenz des Kreises
        }).addTo(this.map);
      },
      async recalculateArea(zip, init = false) {
        this.reload = true;
        console.log('recalculateArea with:zip: ' + zip + ' and init: ' + init);
        if (!init) {
          this.unsaved = true;
        }
        if (zip) {
          this.readyForSave = false;
          if (this.activeZip.includes(zip)) {
            this.activeZip = this.activeZip.filter(item => item !== zip);
          } else {
            this.activeZip.push(zip);
            this.activeZip = this.activeZip.filter(item => item !== null);
          }
          this.zipPostcodes = this.postcodes.filter(postalCode =>
            this.activeZip.some(button => postalCode.zip.startsWith(button)),
          );
        } else {
          this.radiusPostcodes = [];
          const earthRadiusKm = 6371;
          for (const location of this.locations) {
            const locationRadius = this.formdata.radius.find(item => item.id === location.id).radius;
            if (isNaN(locationRadius)) {
              notification['error'](this.$t('companyArea.components.map.radiusErrorNotification'));
              return Promise.resolve(true);
            }

            let currentPostcode = this.postcodes.find(obj => obj.id === location.postcode_id);
            // Loop durch alle Ortschaften
            for (const postcode of this.postcodes) {

              // Location-Informationen aus dem Location-String extrahieren
              const locationLatitude = postcode.latitude;
              const locationLongitude = postcode.longitude;

              // Haversine-Formel anwenden
              const dLat = this.deg2rad(locationLatitude - currentPostcode.latitude);
              const dLon = this.deg2rad(locationLongitude - currentPostcode.longitude);
              const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.deg2rad(currentPostcode.latitude)) *
                Math.cos(this.deg2rad(locationLatitude)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
              const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              const distance = earthRadiusKm * c;

              // Überprüfen, ob die Ortschaft innerhalb des Radius liegt
              if (distance <= locationRadius) {
                const updatedPostcode = {
                  ...postcode,
                  location_id: location.id,
                  distance: distance,
                };
                this.radiusPostcodes.push(updatedPostcode);
              }
            }
          }
        }

        let companyPostcodes = [...new Set([...this.radiusPostcodes, ...this.zipPostcodes])];
        let result = await this.samplePostcodes(companyPostcodes, false);
        this.reload = false;
        if (result === true) {
          if (zip) {
            this.readyForSave = true;
          }
          return Promise.resolve(true);
        }
      },
      async samplePostcodes(companyPostcodes) {
        let companyAreaPostcodes = {};
        for (let postcode of companyPostcodes) {
          if (!companyAreaPostcodes.hasOwnProperty(postcode.state_code)) {
            companyAreaPostcodes[postcode.state_code] = [];
          }
          if (!companyAreaPostcodes[postcode.state_code].includes(postcode)) {
            companyAreaPostcodes[postcode.state_code].push(postcode);
          }
        }
        if (Object.keys(this.companyPostcodes).length > Object.keys(companyAreaPostcodes).length) {
          for (const area in this.companyPostcodes) {
            if (!companyAreaPostcodes.hasOwnProperty(area) || companyAreaPostcodes[area].length === 0) {
              this.companyPostcodes[area] = [];
              this.layer[area].removeFrom(this.map);
            }
          }
        }
        let i = 0;
        for (const area in companyAreaPostcodes) {
          if (!this.companyPostcodes.hasOwnProperty(area) || this.companyPostcodes[area].length !== companyAreaPostcodes[area].length) {
            this.companyPostcodes[area] = companyAreaPostcodes[area];
            await this.calcChoroplethData(area, this.companyPostcodes[area]);
          }
          i++;
        }
        if (i === Object.keys(companyAreaPostcodes).length) {
          return Promise.resolve(true);
        }
      },
      async calcChoroplethData(area, postcodes) {
        const geoJsonData = [];
        let geoJson = this.geoJson;
        if (this.geoJson.length > 0) {
          postcodes.forEach(function (obj) {
            if (geoJson.some(item => item.zip === obj.zip)) {
              geoJson.filter(item => item.zip === obj.zip).forEach(function (geoItem) {
                if (!geoJsonData.includes(geoItem)) {
                  geoJsonData.push(geoItem);
                }
              });
            }
          });
        }
        let data = [{
          type: 'FeatureCollection',
          features: [],
        }];
        geoJsonData.forEach(function (geoItem) {
          data[0].features.push({
            type: 'Feature',
            properties: { id: geoItem.id, name: geoItem.name, zip: geoItem.zip, value: 2 },
            geometry: {
              type: 'Polygon',
              coordinates: JSON.parse(geoItem.geojson),
            },
          });
        });
        await this.updateChoroplethData(area, data);
        return new Promise(resolve => setTimeout(() => resolve(), 0));

      },
      getColor(value) {
        // Farbskala basierend auf dem Wert festlegen
        // Hier können Sie die Farben und Schwellenwerte anpassen
        return value > 2 ? '#8b233b' :
          value > 1 ? '#8d3882' :
            value > 0.5 ? '#bae4b3' :
              '#edf8e9';
      },
      checkSpecificAreaSettings() {
        let radius = [];
        let zip = [];
        if (this.classification.radius) {
          radius = JSON.parse(JSON.stringify(this.classification.radius));
          zip = JSON.parse(JSON.stringify(this.classification.zip));
        } else {
          this.locations.forEach(obj => radius.push({ id: obj.id, radius: obj.radius }));
          zip = JSON.parse(JSON.stringify(this.locations.find(obj => obj.headquarter === 1).zip));
        }
        this.formdata.radius = radius;
        this.activeZip = zip ? zip : [];
      },
      async updateChoroplethData(area, data) {
        if (this.layer.hasOwnProperty(area)) {
          this.layer[area].removeFrom(this.map); // Entfernen des alten Layers von der Karte
        } else {
          this.layer[area] = null;
        }
        this.layer[area] = L.geoJSON(data, {
          style: (feature) => {
            // Stil für jeden Kanton basierend auf dem Wert festlegen
            const value = feature.properties.value;
            return {
              fillColor: this.getColor(value),
              weight: 1,
              opacity: 0.4,
              color: 'white',
              fillOpacity: 0.5,
            };
          },
          onEachFeature: (feature, layer) => {
            // Popup mit Kanton-Informationen hinzufügen
            layer.bindPopup(`Ort: ${feature.properties.name}<br>PLZ: ${feature.properties.zip}`);
          },
        }).addTo(this.map);
        return Promise.resolve(true);

      },
      deg2rad(deg) {
        return deg * (Math.PI / 180);
      },
    },
  };
</script>

<style scoped>

</style>
