<template>
  <div v-if="currentForm && currentForm.hasOwnProperty('id')" class="flex justify-between mt-5">
    <div>
      <button class="font-semibold" @click="show.panel = !show.panel">
        {{
          $t('companyArea.settings.snippets.leadFilters.directPurchaseSettings', {formName: name})
        }}
      </button>
      <p class="text-xs text-gray-500">{{ $t('companyArea.settings.directSale.directBuySettingsInfo') }}</p>
    </div>
    <circle-button :icon="['fas','caret-down']" size="sm" @click="show.panel = !show.panel" />
  </div>
  <div v-if="show.panel">
    <div v-if="formLeadFilters.length === 0"
         class="mt-3 mb-4 bg-gray-100 rounded border border-gray-200 p-3"
    >
      {{ $t('companyArea.settings.snippets.leadFilters.selectionModeInfo') }}
      <div class="flex justify-center mt-3">
        <button class="btn btn-primary btn-sm" @click="storeLeadFilter()">
          {{ $t('companyArea.settings.directSale.enableDirectBuy') }}
        </button>
      </div>
    </div>
    <confirmation-modal v-if="show.editLeadFilter"
                        :content-size="true"
                        :close-button="false"
                        @close="show.editLeadFilter = false; updateLeadFilter()"
    >
      <lead-filters v-model:lead-filter="leadFilter"
                    v-model:assistant="show.assistant"
                    :form="currentForm"
                    @close="updateLeadFilter"
      />
    </confirmation-modal>
    <div v-else-if="locations.length > 0">
      <div class="flex flex-col justify-between">
        <div class="mt-3">
          <div v-for="formLeadFilter in formLeadFilters"
               class="mb-4 bg-gray-100 rounded border border-gray-200 p-3"
          >
            <div class="flex md:justify-between flex-col md:flex-row">
              <div class="text-lg">
                <span class="text-lg font-semibold">{{ getDistance(formLeadFilter) }}</span> -
                <span v-if="leadFilterFieldsCount(formLeadFilter) === 0">{{ $t('companyArea.settings.snippets.leadFilters.noActiveFilters') }}</span>
                <span v-else>{{ $t('companyArea.settings.snippets.leadFilters.filtersActive', {leadFilterFieldsCount: leadFilterFieldsCount(formLeadFilter)}) }}</span>
              </div>
              <div class="flex md:justify-end">
                <button class="btn btn-sm btn-primary mr-2" @click="editLeadFilter(formLeadFilter)">
                  {{ $t('companyArea.settings.directSale.edit') }}
                </button>
                <button class="btn btn-sm btn-secondary" @click="deleteLeadFilter(formLeadFilter)">
                  {{ $t('companyArea.settings.directSale.delete') }}
                </button>
              </div>
            </div>

            <div class="text-xs mt-1 mb-2">
              <span v-if="formLeadFilter.distance">{{
                $t('companyArea.settings.directSale.buyDistanceInfo', {distance: formLeadFilter.distance})
              }}<br></span>
              <span v-else>{{ $t('companyArea.settings.directSale.buyAllInfo') }} </span>
              <span v-if="leadFilterFieldsCount(formLeadFilter) > 0">, {{
                $t('companyArea.settings.directSale.buyFilterInfo')
              }}</span>
            </div>
            <div v-if="formLeadFilter.distance" class="text-xs text-red-700">
              {{ $t('companyArea.settings.directSale.filterRadiusInfo') }}
            </div>
          </div>
          <div v-if="formLeadFilters.length === 1">
            <div class="text-xs text-gray-500">
              {{ $t('companyArea.settings.snippets.leadFilters.defineNewRadius') }}
            </div>
            <button class="btn btn-sm btn-secondary mt-3"
                    @click="show.addRadius = true"
            >
              {{
                $t('companyArea.settings.directSale.addRadius')
              }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal v-if="show.addRadius" @close="show.addRadius = false">
      {{ $t('companyArea.settings.directSale.radiusInfo') }}
      <input v-model="formdata.newDistance" type="number" class="border border-gray-400 w-full mt-2">
      <div class="flex justify-center mt-3">
        <button class="btn btn-md btn-primary mt-2" @click="storeLeadFilter(newDistance)">
          {{ $t('companyArea.settings.directSale.addFilter') }}
        </button>
      </div>
    </confirmation-modal>
  </div>
  <div v-else>
    <span v-if="formLeadFilters.length > 0" class="text-green-500 pt-3 font-semibold">{{ $t('companyArea.settings.directSale.directPurchaseActive') }}</span>
    <span v-else class="text-red-600 pt-3 font-semibold">{{ $t('companyArea.settings.directSale.directPurchaseInactive') }}</span>
    <button class="btn btn-sm btn-primary mt-2 ml-4" @click="show.panel = true">
      {{ $t('companyArea.settings.directSale.edit') }}
    </button>
  </div>
</template>

<script>
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import companyApi from '../../connections/company';
import CheckboxField from './snippets/checkboxField.vue';
import SelectField from './snippets/selectField.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import LeadFilters from './snippets/LeadFilters.vue';

export default {
    name: 'DirectSaleInArea',
    components: {LeadFilters, CircleButton, ConfirmationModal, Layout, CheckboxField, SelectField},
    props: {
        currentClassification: {}
    },
    data() {
        return {
            locations: [],
            forms: [],
            leadFilters: [],
            leadFilter: {},
            formdata: {
                leadFilterId: '',
                form_id: '',
                distance: '',
                newDistance: '',
            },
            show: {
                addRadius: false,
                deleteFilter: false,
                editLeadFilter: false,
                assistant: false,
                panel: false
            },

        };
    },
    computed: {
        currentForm() {
            if (this.forms.length > 0) {
                return this.forms.find(obj => obj.classification_number === this.currentClassification.number);
            } else {
                return {}
            }
        },
      name() {
          return this.$t('classification.' + this.currentForm.translation_key)
      },
        formLeadFilters() {
          if (this.currentForm && this.currentForm.hasOwnProperty('id')) {
            return this.leadFilters
                .filter(obj => obj.form_id === this.currentForm.id)
                .sort((a, b) => a.id - b.id);
          } else {
            return [];
          }
        },
    },
    created() {
        companyApi.get('companies/' + this.$route.params.company_id + '/forms/lead-filters').then(response => response.data.notificationType === 'success' ? [
            this.forms = response.data.content.company.forms,
            this.leadFilters = response.data.content.company.lead_filters] : '');

    },
    mounted() {
        this.locations = store.state.company.website.locations;
    },
    methods: {
        storeLeadFilter() {
            this.show.addRadius = false;
            this.formdata.form_id = this.currentForm.id;
            companyApi.post('companies/' + this.$route.params.company_id + '/forms/lead-filters', this.formdata, {
                notification: true,
                progress: true
            }).then(response => response.data.notificationType === 'success'
                ?
                [this.leadFilters.push(response.data.content.leadFilter), this.leadFilter = response.data.content.leadFilter, this.formdata.newDistance = '', this.show.editLeadFilter = true, this.show.assistant = true]
                : '');
        },
        deleteLeadFilter(leadFilter) {
            companyApi.delete('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + leadFilter.id + '/lead-filter-fields',
                {notification: true, progress: true}).then(response =>
                this.leadFilters = this.leadFilters.filter(obj => obj.id !== leadFilter.id));
        },
        editLeadFilter(leadFilter) {
            this.leadFilter = leadFilter;
            this.show.editLeadFilter = true;
            this.show.assistant = false;
        },
        updateLeadFilter() {
            this.leadFilters = this.leadFilters.filter(item => item.id !== this.leadFilter.id);
            this.leadFilters.push(this.leadFilter);
            this.show.editLeadFilter = false;
            this.show.assistant = false;
        },

        getDistance(leadFilter) {
            if (leadFilter.distance) {
                return leadFilter.distance + ' km';
            } else {
                return this.$t('companyArea.settings.area.wholeArea');
            }
        },
        leadFilterFieldsCount(leadFilter) {
            let count = 0;
            if (leadFilter.short_term) count++;
            if (leadFilter.long_term) count++;
            if (leadFilter.phone) count++;
            if (leadFilter.price_quality) count++;
            if (leadFilter.desired_quotes) count++;
            if (leadFilter.hasOwnProperty('lead_filter_fields')) {
                // grouping function
                let singleFilters = [];
                leadFilter.lead_filter_fields.forEach(obj => singleFilters.includes(obj.field_id) ? '' : singleFilters.push(obj.field_id));
                count += singleFilters.length;
            }
            return count;
        },

    }
};

</script>

<style scoped>

</style>
