<template>
  <div>
    <div v-if="step === 1">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.regionFilter') }}</div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-md btn-primary mx-2" @click="$emit('close-assistant')">{{ $t('companyArea.settings.snippets.leadFilterAssistant.allLeads') }}</button>
        <button class="btn btn-md btn-primary mx-2" @click="leadFilter.distance ? step = 3 : step = 2">{{ $t('companyArea.settings.snippets.leadFilterAssistant.setFilter') }}</button>
      </div>
    </div>

    <div v-if="step === 2">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.distanceFilter') }}</div>
      <div class="relative mt-4">
        <input v-model="formdata.distance"
               type="number"
               class="pr-12 w-full border border-gray-400"
               @keyup.enter="updateLeadFilter"
        >

        <div class="absolute right-2 top-1.5 text-xxs z-10">
          km
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button v-if="formdata.distance" class="btn btn-md btn-primary mx-2" @click="updateLeadFilter">{{ $t('general.next') }}</button>
        <button class="btn btn-md btn-primary mx-2" @click="step = 3">{{ $t('general.noMatter') }}</button>
      </div>
    </div>

    <div v-if="step === 3">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.shortTerm') }}</div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-md btn-primary mx-2 uppercase" @click="step = 5">{{ $t('general.yes') }}</button>
        <button class="btn btn-md btn-primary mx-2 uppercase" @click="step = 4">{{ $t('general.no') }}</button>
      </div>
    </div>

    <div v-if="step === 4">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.daysInFuture') }}</div>
      <div class="relative mt-4">
        <input v-model="formdata.short_term"
               type="number"
               class="pr-12 w-full border border-gray-400"
               @keyup.enter="updateLeadFilter"
        >

        <div class="absolute right-2 top-1.5 text-xxs z-10">
          {{ $t('companyArea.settings.snippets.leadFilterAssistant.days') }}
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button v-if="formdata.short_term" class="btn btn-md btn-primary mx-2" @click="updateLeadFilter">{{ $t('general.next') }}</button>
        <button class="btn btn-md btn-primary mx-2" @click="step = 3">{{ $t('general.back') }}</button>
      </div>
    </div>

    <div v-if="step === 5">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.longTerm') }}</div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-md btn-primary mx-2 uppercase" @click="step = 7">{{ $t('general.yes') }}</button>
        <button class="btn btn-md btn-primary mx-2 uppercase" @click="step = 6">{{ $t('general.no') }}</button>
      </div>
    </div>

    <div v-if="step === 6">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.longTermMax') }}</div>
      <div class="relative mt-4">
        <input v-model="formdata.long_term"
               type="number"
               class="pr-12 w-full border border-gray-400"
               @keyup.enter="updateLeadFilter"
        >

        <div class="absolute right-2 top-1.5 text-xxs z-10">
          {{ $t('companyArea.settings.snippets.leadFilterAssistant.months') }}
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button v-if="formdata.long_term" class="btn btn-md btn-primary mx-2" @click="updateLeadFilter">{{ $t('general.next') }}</button>
        <button class="btn btn-md btn-primary mx-2" @click="step = 5">{{ $t('general.back') }}</button>
      </div>
    </div>

    <div v-if="step === 7">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.noPhoneNumber') }}</div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-md btn-primary mx-2" @click="formdata.phone = 1; updateLeadFilter()">{{ $t('general.no') }}</button>
        <button class="btn btn-md btn-primary mx-2" @click="step = 8">{{ $t('general.noMatter') }}</button>
      </div>
    </div>

    <div v-if="step === 8">
      <div class="text-center text-lg font-semibold">{{ $t('companyArea.settings.snippets.leadFilterAssistant.cheapJobPriceLeads') }}</div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-md btn-primary mx-2" @click="formdata.price_quality = 0.3; updateLeadFilter()">{{ $t('general.no') }}</button>
        <button class="btn btn-md btn-primary mx-2" @click="step = 9">{{ $t('general.noMatter') }}</button>
      </div>
    </div>

    <div v-if="step === 9">
      <component :is="inputField.type"
                 v-if="inputFields.length > 0"
                 class="relative"
                 :field="inputField"
                 :formdata="formdata"
                 :assistant="true"
                 :filters="leadFilter.lead_filter_fields"
                 :option-fields="inputField.type === 'selectField' || inputField.type === 'radioField' ? form.fields : []"
                 @storeFilter="$emit('store-filter'); dynamicStep++"
                 @syncFilter="$emit('sync-filter'); dynamicStep++"
                 @deleteFilter="$emit('delete-filter'); dynamicStep++"
                 @nextStep="dynamicStep++"
      />
    </div>
    <p class="text-xs text-center text-gray-500 mt-6" v-html="$t('companyArea.settings.snippets.leadFilterAssistant.tippOnlyFilterImportant')" />
    <spinner v-if="waitForServerResponse" />
  </div>
</template>

<script>
import companyApi from '../../../connections/company';
import SelectField from './selectField.vue';
import CheckboxField from './checkboxField.vue';
import Spinner from '../../../snippets/Spinner.vue';

export default {
  name: 'LeadFilterAssistant',
  components: { Spinner, CheckboxField, SelectField },
  props: {
    form: Object,
    leadFilter: Object,
    formdata: Object
  },
  emits: ['sync-filter','delete-filter','store-filter','close-assistant','update-lead-filter'],
  data() {
    return {
      step: 1,
      dynamicStep: 0,
      // formdata: {
      //   dynamic_form: {},
      //   include: [],
      //   leadFilterId: '',
      //   form_id: '',
      //   distance: '',
      //   short_term: '',
      //   long_term: '',
      //   newDistance: '',
      //   phone: '',
      //   desired_quotes: '',
      //   price_quality: '',
      // },
      show: {
        detail: '',
      },
      includedOption: [],
      waitForServerResponse: false
    };
  },
  computed: {
    inputFields() {
      if(this.form.hasOwnProperty('fields')) {
        return this.form.fields.filter(obj => obj.parent === null && obj.lead_filter && (obj.type === 'checkboxField' || obj.type === 'selectField'));
      } else {
        return [];
      }
    },
    inputField() {
      return this.inputFields[this.dynamicStep]
    },
    getOptions: function() {
      let options = this.form.fields.filter(value => value.parent === this.inputFields[this.dynamicStep].id);
      options.sort(function(a, b) {
        if(a.sort_form > b.sort_form) { return 1; }
        if(a.sort_form < b.sort_form) { return -1; }
        return 0;
      });
      return options;
    },
  },
  watch: {
    dynamicStep() {
      if(this.dynamicStep === this.inputFields.length) {
        this.$emit('close-assistant')
      }
    },
    leadFilter: {
      immediate: true,
      handler() {
        this.waitForServerResponse = false
      }, deep: true,
    },
  },
  mounted() {
    console.log(this.leadFilter.distance)
  },
  methods: {
    updateLeadFilter() {
      this.waitForServerResponse = true
      this.$emit('update-lead-filter')
      this.step++
    },
    storeLeadFilterField() {
      companyApi.post('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/fields', this.formdata, { notification: true, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              this.leadFilter.lead_filter_fields.push(response.data.content.leadFilter) : '');
    },
    includeOption(option) {
      if(this.includedOption.includes(option.id)) {
        this.includedOption = this.includedOption.filter(item => item !== option.id);
      } else {
        this.includedOption.push(option.id);
      }
    },
  }
};
</script>

<style scoped>

</style>
