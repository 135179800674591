<template>
  <div class="w-full flex justify-between mt-5 px-5 sm:px-0">
    <folder-circle :amount="leads.newLeads.length.toString()"
                   :label="$t('companyArea.leads.leadOverview.leadOverviewWrapper.new')"
                   :color="$route.name === 'NewLeads' ? 'bg-yellow-400 border-yellow-400 text-white' : 'bg-white border-yellow-400 text-yellow-400'"
                   :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'"
                   data-cy="dashboard-new-leads"
                   @click="$router.push({ name: 'NewLeads'})"
    />
    <folder-circle :amount="leads.allLeads.length.toString()"
                   :label="$t('companyArea.leads.leadOverview.leadOverviewWrapper.bought')"
                   :color="$route.name === 'PendingLeads' ? 'bg-blue-400 border-blue-400 text-white' : 'bg-white border-blue-400 text-blue-400'"
                   :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'"
                   data-cy="dashboard-pending-leads"
                   @click="$router.push({ name: 'PendingLeads'})"
    />
    <folder-circle :amount="leads.confirmedQuotes.length.toString()"
                   :label="$t('companyArea.leads.leadOverview.leadOverviewWrapper.confirmed')"
                   :color="$route.name === 'ConfirmedQuotes' ? 'bg-green-500 border-green-500 text-white' : 'bg-white border-green-500 text-green-500'"
                   :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'"
                   data-cy="dashboard-confirmed-quotes"
                   @click="$router.push({ name: 'ConfirmedQuotes'})"
    />
    <folder-circle v-if="leads.requiredActions.length > 0"
                   :amount="leads.requiredActions.length.toString()"
                   :label="$t('companyArea.leads.leadOverview.leadOverviewWrapper.urgent')"
                   :color="$route.name === 'RequiredActions' ? 'bg-red-600 border-red-600 text-white' : 'bg-white border-red-600 text-red-600'"
                   :class="{ 'hidden sm:flex':(leads.requiredActions.length === 0)}"
                   :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'"
                   data-cy="dashboard-required-actions"
                   @click="$router.push({ name: 'RequiredActions'})"
    />

    <folder-circle :amount="'A'"
                   :label="$t('companyArea.leads.leadOverview.leadOverviewWrapper.archiv')"
                   :color="$route.name === 'Archive' ? 'bg-gray-500 border-gray-500 text-white' : 'bg-white border-gray-500 text-gray-500'"
                   class="hidden sm:flex"
                   data-cy="dashboard-archive"
                   @click="$router.push({ name: 'Archive'})"
    />
  </div>

  <slot v-if="$route.name === 'Archive'" name="filter" />
  <div v-else-if="!['LeadFocus','CompanyChat','CompanyQuote','LeadMenu','CompanyNote'].includes($route.name)" class="flex flex-col sm:flex-row sm:justify-center px-1 sm:px-3 mt-8">
    <div v-if="companyLeads.length > 0 && $route.name === 'NewLeads'" class="h-10">
      <button class="text-xl rounded-full h-10 bg-white hover:bg-gray-50 border-none shadow px-4 mr-2"
              @click="calendar = ! calendar; dateFilter = null"
      >
        <font-awesome-icon :icon="['fas','calendar-plus']" />
      </button>
    </div>
    <div v-if="$route.name === 'PendingLeads'" class="relative w-min hidden sm:block sm:mr-5">
      <select v-model="statusFilter" class="border-none shadow rounded-full w-56 sm:w-52">
        <option value="all">
          {{ $t('general.all') }}
        </option>
        <option value="1">{{ $t('companyLeadStatus.open') }}</option>
        <option value="13">{{ $t('companyLeadStatus.visitScheduled') }}</option>
        <option value="2">{{ $t('companyLeadStatus.quoteSent') }}</option>
        <option value="3">{{ $t('companyLeadStatus.confirmed') }}</option>
        <option value="6">{{ $t('companyLeadStatus.lost') }}</option>
        <option value="10">{{ $t('companyLeadStatus.noQuote') }}</option>
        <option value="11">{{ $t('companyLeadStatus.noQuoteWanted') }}</option>
        <option value="cancel">{{ $t('companyLeadStatus.cancellation') }}</option>
      </select>
    </div>

    <div v-if="$route.name !== 'NewLeads'" class="relative w-min hidden sm:block sm:mr-5">
      <select v-model="filter" class="border-none shadow rounded-full w-56 sm:w-52">
        <option v-if="$route.name !== 'NewLeads'" value="surname">
          {{ $t('companyArea.leads.leadOverview.leadOverviewWrapper.alphabeticallySorted') }}
        </option>
        <option value="created_at" selected="true">
          {{ $t('companyArea.leads.leadOverview.leadOverviewWrapper.newestFirst') }}
        </option>
        <option value="date">{{ $t('companyArea.leads.leadOverview.leadOverviewWrapper.jobDate') }}</option>
      </select>
    </div>


    <div v-if="$route.name !== 'NewLeads'" class="flex ">
      <input v-model="formdata.search"
             type="text"
             class="rounded-full border-none shadow w-56 sm:w-52 sm:mr-5 mt-2 sm:mt-0"
             :placeholder="$t('companyArea.leads.leadOverview.leadOverviewWrapper.search')"
      >
    </div>
    <div class="flex sm:hidden md:flex flex-col items-end sm:mt-0 cursor-pointer"
         @click="$router.push({ name: company.prepaid ? 'Credit' : 'Transactions' })"
    >
      <span class="text-xxs sm:text-xs text-gray-600"
            data-cy="balance-label"
      >{{ company.prepaid ? $t('companyArea.leads.leadOverview.leadOverviewWrapper.saldo') : $t('companyArea.leads.leadOverview.leadOverviewWrapper.currentMonth') }}</span><span class="text-md font-semibold"
                                                                                                                                                                                  :class="{ 'text-red-700':(company.credit < 0) }"
                                                                                                                                                                                  data-cy="balance-value"
      >{{
        company.currency
      }} {{
        company.prepaid ? company.credit : company.currentMonthExpenses
      }}</span>
    </div>
  </div>

  <div v-if="($route.name === 'PendingLeads' || $route.name === 'Archive') && (Object.keys(forms).length > 1 || hideForms.length > 0)" class="w-full flex flex-wrap mt-3 justify-center">
    <div v-for="(name, formId) in forms"
         :key="formId"
         class="px-2 py-1 rounded-md mr-3 mb-3 cursor-pointer text-xs"
         :class="{ 'bg-gray-300 border border-gray-400 hover:bg-gray-300':(hideForms.includes(parseInt(formId))), 'bg-white hover:bg-gray-300':(!hideForms.includes(parseInt(formId)))}"
         @click="filterForms(formId)"
    >
      {{ $t('classification.' + name) }}
    </div>
  </div>


  <account-status :company="company" />

  <div v-if="newLeadNotAvailable" class="text-center rounded bg-yellow-400 mt-5 p-2">
    <p>{{ $t('companyArea.leads.leadOverview.leadOverviewWrapper.leadAlreadyTaken') }}</p>
    <p class="mt-2" v-html="$t('companyArea.leads.leadOverview.leadOverviewWrapper.neverBeToLateAgain')" />
    <div class="flex justify-center mt-2">
      <router-link :to="{ name: 'Area' }" class="btn btn-sm btn-primary">
        {{ $t('companyArea.leads.leadOverview.leadOverviewWrapper.setDirectBuyFilter') }}
      </router-link>
    </div>
  </div>

  <div v-if="$route.name === 'PendingLeads' || $route.name === 'Archive' || $route.name === 'ConfirmedQuotes' || $route.name === 'NewLeads'">
    <div v-if="companyLeads.length === 0" class="flex justify-center mt-7">
      <p v-if="$route.name === 'Archive'"
         class="text-center text-base font-semibold"
         v-html="$t('companyArea.leads.leadOverview.leadOverviewWrapper.searchNoResult')"
      />
      <p v-else class="text-center text-base font-semibold">
        {{ $t('companyArea.leads.leadOverview.leadOverviewWrapper.noLeadsHere') }}
      </p>
    </div>
    <div v-else-if="$route.name === 'PendingLeads' || $route.name === 'Archive'">
      <table class="hidden sm:block panel w-full">
        <thead>
          <tr>
            <th class="py-2 px-4 border-b text-left">
              {{ $t('general.type') }} / ID
            </th>
            <th class="py-2 px-4 border-b text-left">
              {{ $t('general.status') }}
            </th>
            <th class="py-2 px-4 border-b text-left">
              {{ $t('general.customer') }}
            </th>
            <th class="hidden xl:table-cell py-2 px-4 border-b text-left">
              {{ $t('general.contact') }}
            </th>
            <th class="hidden lg:table-cell py-2 px-4 border-b text-left">
              {{ $t('general.jobDate') }}
            </th>
            <th class="hidden 2xl:table-cell py-2 px-4 border-b text-left">
              {{ $t('general.competitors') }}
            </th>
            <th class="hidden md:table-cell py-2 px-4 border-b text-left">
              {{ $t('general.leadDate') }}
            </th>
            <th class="hidden lg:table-cell py-2 px-4 border-b text-left">
              {{ $t('general.price') }}
            </th>
            <th class="table-cell py-2 px-4 border-b text-left">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="companyLead in companyLeads"
              :key="'list'+companyLead.id"
              class="hover:bg-blue-100"
          >
            <td class="py-2 px-4 border-b cursor-pointer" @click="$router.push({ name: 'CompanyNote', params: { lead_uuid: companyLead.lead_uuid}})">
              {{ $t('classification.' + companyLead.lead.form.translation_key) }}<br>
              <span class="text-xs text-gray-400">{{ companyLead.lead.uuid }}</span>
            </td>
            <td class="py-2 px-4 border-b">
              <status-circle :company-lead="companyLead" :status-change="true" area="company" />
            </td>
            <td class="py-2 px-4 border-b cursor-pointer" @click="$router.push({ name: 'CompanyNote', params: { lead_uuid: companyLead.lead_uuid}})">
              {{ companyLead.lead.surname }} {{ companyLead.lead.name }}<br>
              <span class="text-gray-400">{{ companyLead.lead.postcode }} {{ companyLead.lead.city }}</span>
              <span class="lg:hidden"><br>{{ this.dayjsHelper.showDate(companyLead.date) }}</span>
            </td>
            <td class="hidden xl:table-cell py-2 px-4 border-b cursor-pointer" @click="$router.push({ name: 'CompanyNote', params: { lead_uuid: companyLead.lead_uuid}})">
              <span v-if="companyLead.lead.email !== 'deleted@deleted'">{{ companyLead.lead.email.length > 25 ? companyLead.lead.email.slice(0,25)+'...' : companyLead.lead.email }}</span><br>
              <span v-if="companyLead.lead.phone">{{ companyLead.lead.phone }}</span>
            </td>
            <td class="hidden lg:table-cell py-2 px-4 border-b cursor-pointer" @click="$router.push({ name: 'CompanyNote', params: { lead_uuid: companyLead.lead_uuid}})">
              {{ this.dayjsHelper.showDate(companyLead.date) }}<br>
              <span class="text-gray-400">{{ companyLead.lead.flexible_date ? $t('form.flexibleDate') : $t('form.notFlexible') }}</span>
            </td>
            <td class="hidden 2xl:table-cell py-2 px-4 border-b">
              {{ companyLead.lead.taken_by }} / {{ companyLead.lead.desired_quotes }}
            </td>
            <td class="hidden md:table-cell py-2 px-4 border-b">
              {{ this.dayjsHelper.showDateTime(companyLead.lead.valid_at) }}
            </td>
            <td class="hidden lg:table-cell py-2 px-4 border-b">
              <lead-costs :credit-transactions="companyLead.lead.credit_transactions" :clickable="false" />
            </td>
            <td class="table-cell py-2 px-4 border-b">
              <button v-if="companyLead.status !== 3" class="btn btn-sm btn-primary" @click="confirmed(companyLead.id)">{{ $t('companyArea.leads.leadOverview.leadOverviewWrapper.confirmed')}}</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="sm:hidden">
        <slot v-for="companyLead in companyLeads"
              :company-lead="companyLead"
              :desktop="false"
        />
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 sm:mt-5">
        <div class="hidden sm:flex xl:hidden flex-col pr-2">
          <overview-calendar v-if="$route.name === 'NewLeads' && calendar"
                             v-model:date-filter="dateFilter"
                             :events="events"
          />
          <slot v-for="companyLead in companyLeadsInCols[0]" :company-lead="companyLead" :desktop="true" />
        </div>
        <div class="hidden sm:flex xl:hidden flex-col pl-2">
          <slot v-for="companyLead in companyLeadsInCols[1]" :company-lead="companyLead" :desktop="true" />
        </div>
        <div class="hidden xl:flex flex-col pr-2">
          <overview-calendar v-if="$route.name === 'NewLeads' && calendar"
                             v-model:date-filter="dateFilter"
                             :events="events"
          />

          <slot v-for="companyLead in companyLeadsInCols[2]" :company-lead="companyLead" :desktop="true" />
        </div>
        <div class="hidden xl:flex flex-col px-2">
          <slot v-for="companyLead in companyLeadsInCols[3]" :company-lead="companyLead" :desktop="true" />
        </div>
        <div class="hidden xl:flex flex-col pl-2">
          <slot v-for="companyLead in companyLeadsInCols[4]" :company-lead="companyLead" :desktop="true" />
        </div>
        <div class="sm:hidden">
          <overview-calendar v-if="$route.name === 'NewLeads' && !calendar"
                             v-model:date-filter="dateFilter"
                             :events="events"
          />
          <slot v-for="companyLead in companyLeads" :company-lead="companyLead" :desktop="false" />
        </div>
      </div>
    </div>
  </div>

  <spinner v-if="showSpinner" />
</template>

<script>
  import store from '../../../store/mainStore.js';
  import FolderCircle from './snippets/FolderCircle.vue';
  import AccountStatus from '../../components/AccountStatus.vue';
  import OverviewCalendar from './snippets/OverviewCalendar.vue';
  import Spinner from '../../../snippets/Spinner.vue';
  import LeadCosts from './snippets/LeadCosts.vue';
  import StatusCircle from '../../../snippets/StatusCircle.vue';

  export default {
    name: 'LeadOverviewWrapper',
    components: { OverviewCalendar, StatusCircle, LeadCosts, Spinner, AccountStatus, FolderCircle },
    props: {},
    data() {
      return {
        show: {
          modal: false,
        },
        formdata: {
          search: '',
        },
        filter: 'created_at',
        statusFilter: 'all',
        dateFilter: null,
        periodFilter: 'all',
        calendar: true,
        hideForms: [],
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      newLeadNotAvailable() {
        if (this.$route.name === 'NewLeads' && this.$route.params.lead_uuid) {
          if (this.leads.newLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid)) {
            return false;
          } else {
            return true;
          }
        } else {
          return null;
        }
      },
      showSpinner() {
        return store.state.company.leads.loading;
      },
      leads() {
        let leads = JSON.parse(JSON.stringify(store.state.company.leads));

        return {
          newLeads: leads.newLeads,
          allLeads: leads.companyLeads.filter(obj =>
            this.statusFilter === 'all' ? true : (this.statusFilter === 'cancel' ? [7,8,9].includes(obj.status) : obj.status === Number(this.statusFilter))),
          confirmedQuotes: leads.companyLeads.filter(obj => obj.status === 3),
          requiredActions: leads.companyLeads.filter(obj => obj.lead?.required_actions?.length > 0 && obj.lead.required_actions.find(item => item.duedate !== null)),
          archivedLeads: leads.searchLeads,
        };
      },
      companyLeads() {
        let companyleads = [];
        if (this.$route.name === 'NewLeads') {
          if (this.dateFilter) {
            companyleads = this.leads.newLeads.filter(obj => this.dayjsHelper.dayjs(obj.date).format('YYYY-MM-DD') === this.dayjsHelper.dayjs(this.dateFilter).format('YYYY-MM-DD'));
          } else {
            companyleads = this.leads.newLeads;
          }

          // companyleads.unshift({ id: 'calendar' });
        } else if (this.$route.name === 'PendingLeads') {
          companyleads = this.leads.allLeads;
        } else if (this.$route.name === 'ConfirmedQuotes') {
          companyleads = this.leads.confirmedQuotes;
        } else if (this.$route.name === 'RequiredActions') {
          companyleads = this.leads.requiredActions;
        } else {
          companyleads = this.leads.archivedLeads;
        }
        if (this.periodFilter !== 'all') {
          companyleads = this.filterDataByMonth(companyleads, this.periodFilter);
        }

        if (this.$route.name !== 'Archive' && this.formdata.search !== '') {
          let search = this.formdata.search.toLowerCase();
          companyleads = companyleads.filter(obj => obj.lead.name.toLowerCase().includes(search) || obj.lead.surname.toLowerCase().includes(search) || obj.lead.city.toLowerCase().includes(search));
        }
        if(this.hideForms.length > 0) {
          companyleads = companyleads.filter(obj => this.hideForms.includes(obj.lead.form.id) === true)
        }
        let criteria = this.filter;
        companyleads.sort(function (a, b) {
          if (criteria === 'surname') {
            if (a.lead[criteria] < b.lead[criteria]) {
              return -1;
            }
            if (a.lead[criteria] > b.lead[criteria]) {
              return 1;
            }
          } else if (criteria === 'created_at') {
            if (a[criteria] < b[criteria]) {
              return 1;
            }
            if (a[criteria] > b[criteria]) {
              return -1;
            }
          } else {
            if (a[criteria] < b[criteria]) {
              return -1;
            }
            if (a[criteria] > b[criteria]) {
              return 1;
            }
          }
          return 0;
        });

        if (this.$route.params.lead_uuid) {
          const targetUuid = this.$route.params.lead_uuid;

          // Find the lead with the specified UUID
          const targetLead = companyleads.filter(lead => lead.lead_uuid === targetUuid);

          // Get all other leads excluding the target lead
          const otherLeads = companyleads.filter(lead => lead.lead_uuid !== targetUuid);

          // Return the combined array with the target lead at the beginning
          companyleads = [...targetLead, ...otherLeads];
        }

        return companyleads;
      },
      forms() {
        let companyleads = [];
        if (this.$route.name === 'PendingLeads') {
          companyleads = this.leads.allLeads;
        } else {
          companyleads = this.leads.archivedLeads;
        }

        return  companyleads.reduce((acc, companylead) => {
          const id = companylead.lead.form.id;
          const translation_key = companylead.lead.form.translation_key;
          acc[id] = translation_key;

          return acc;
        }, {});
      },
      companyLeadsInCols() {
        let col2 = this.$route.name === 'NewLeads' && this.calendar ? 2 : 1;
        let col3 = this.$route.name === 'NewLeads' && this.calendar ? 2 : 1;
        let col2_1 = [];
        let col2_2 = [];
        let col3_1 = [];
        let col3_2 = [];
        let col3_3 = [];

        if (this.companyLeads.length > 0) {

          for (let i = 0; i < this.companyLeads.length; i++) {
            if (col2 === 1) {
              col2_1.push(this.companyLeads[i]);
              col2 = 2;
            } else {
              col2_2.push(this.companyLeads[i]);
              col2 = 1;
            }

            if (col3 === 1) {
              col3_1.push(this.companyLeads[i]);
              col3 = 2;
            } else if (col3 === 2) {
              col3_2.push(this.companyLeads[i]);
              col3 = 3;
            } else {
              col3_3.push(this.companyLeads[i]);
              col3 = 1;
            }
          }
        }

        return [col2_1, col2_2, col3_1, col3_2, col3_3];
      },
      events() {
        let array = this.leads.newLeads;
        for (let i = 0; i < array.length; i++) {
          array[i].start = this.dayjsHelper.dayjs(array[i].date).toDate(); // .format('YYYY-MM-DD HH:mm');
          array[i].end = this.dayjsHelper.dayjs(array[i].date).toDate();
        }
        return array;
      },
      periods() {
        var textOptions = { year: 'numeric', month: 'long' };
        var d = new Date();
        var period = [{ value: 'all', text: 'general.all' }];
        period.push({
          value: d.getFullYear() + '-' + (d.getMonth() + 1),
          text: d.toLocaleDateString(this.i18n.global.locale, textOptions),
        });
        var i;
        for (i = 0; i < 12; i++) {
          d.setMonth(d.getMonth() - 1);
          period.push({
            value: d.getFullYear() + '-' + (d.getMonth() + 1),
            text: d.toLocaleDateString(this.i18n.global.locale, textOptions),
          });
        }
        return period;
      },
    },
    methods: {
      update() {
        this.$forceUpdate;
      },
      filterDataByMonth(data, selectedMonth) {
        const filteredData = data.filter(item => {
          const createdAt = this.dayjsHelper.dayjs(item.created_at);
          const selectedDate = this.dayjsHelper.dayjs(selectedMonth);

          return createdAt.isSame(selectedDate, 'month');
        });

        return filteredData;
      },
      filterForms(form) {
        form = parseInt(form)
        if(this.hideForms.includes(form)) {
          this.hideForms = this.hideForms.filter(item => item !== form)
        } else {
          this.hideForms.push(form)
        }
      },
      confirmed(companyLeadId) {
        store.dispatch('company/confirmedJob', [companyLeadId]);
      },
    },
  };
</script>

<style scoped>

</style>
