<template>
  <div class="panel">
    <div class="text-base font-semibold">{{ $t('companyArea.accounting.credit.topupAndManageCreditCards') }}</div>
    <p class="text-xs sm:text-sm text-gray-400">{{ $t('companyArea.accounting.credit.topupCreditCardInfo') }}</p>
    <text-button size="sm"
                 :icon="['fal','plus']"
                 class="place-self-center mt-3"
                 :text="$t('companyArea.accounting.credit.topupWithNewCreditCard')"
                 @click="openForm(null)"
    />

    <p v-if="stripePaymentMethods.length === 0" class="mt-5">{{ $t('companyArea.accounting.credit.noPaymentMethod') }}</p>
    <table class="w-full mt-5">
      <stripe-payment-methods-overview v-for="stripePaymentMethod in stripePaymentMethods"
                                       :key="'card' + stripePaymentMethod.id"
                                       :company_id="company.id"
                                       :payment-method="stripePaymentMethod"
                                       @charge-payment-method="openForm(stripePaymentMethod.id)"
      />
    </table>

    <stripe-payment :show="showForm"
                    :payment-method-id="paymentMethodId"
                    @update:show="showForm = $event"
    />
  </div>
</template>

<script>
  import StripePaymentMethodsOverview from './../credit/StripePaymentMethodsOverview.vue';
  import TextButton from '../../../snippets/TextButton.vue';
  import StripePayment from './StripePayment.vue';

  export default {
    name: 'StripePanel',
    components: { StripePayment, StripePaymentMethodsOverview, TextButton },
    props: {
      company: {
        type: Object,
        default: function () {
          return {};
        },
      },
    },
    data() {
      return {
        showForm: false,
        paymentMethodId: null,
      };
    },
    computed: {
      stripePaymentMethods() {
        return this.company.stripe_payment_methods;
      },
      mainStripePaymentMethod() {
        if (this.stripePaymentMethods.length > 0) {
          return this.stripePaymentMethods.find(obj => obj.main == true);
        } else {
          return null;
        }
      },
    },
    methods: {
      openForm(paymentMethodId = null) {
        this.paymentMethodId = paymentMethodId;
        this.showForm = true;
      },
      openFormWithMainCard() {
        this.openForm(this.mainStripePaymentMethod.id);
      },
    },
  };
</script>
