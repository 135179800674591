import CompanyStatistics from '../components/companyArea/Achievements/CompanyStatistics.vue';
import Archive from '../components/companyArea/Leads/Archive.vue';
import LeadFocus from '../components/companyArea/Leads/LeadFocus.vue';
import Chat from '../components/sharedComponents/Chat.vue';
import CompanyQuote from '../components/companyArea/Leads/LeadOverview/Quote.vue';
import LeadMenu from '../components/menu/LeadMenu.vue';
import Credit from '../components/companyArea/Accounting/Credit.vue';
import Transactions from '../components/companyArea/Accounting/Transactions.vue';
import Reports from '../components/companyArea/Accounting/Reports.vue';
import CompanyReviews from '../components/companyArea/Achievements/CompanyReviews.vue';
import Area from '../components/companyArea/Settings/Area.vue';
import Classifications from '../components/companyArea/Settings/Classifications.vue';
import Contact from '../components/companyArea/Settings/Contact.vue';
import Employees from '../components/companyArea/Settings/Employees.vue';

import ActiveLeadsOverview from '../components/companyArea/Leads/ActiveLeadsOverview.vue';
import NewLeads from '../components/companyArea/Leads/NewLeads.vue';
import PendingLeads from '../components/companyArea/Leads/PendingLeads.vue';
import ConfirmedQuotes from '../components/companyArea/Leads/ConfirmedQuotes.vue';
import RequiredActions from '../components/companyArea/Leads/RequiredActions.vue';
import ClosedDays from '../components/companyArea/Settings/ClosedDays.vue';
import DirectSale from '../components/companyArea/Settings/DirectSale.vue';
import Invoices from '../components/companyArea/Accounting/Invoices.vue';
import PartnerStatus from '../components/companyArea/Achievements/PartnerStatus.vue';
import CompanyNote from '../components/companyArea/components/CompanyNote.vue';
import CompanyShop from '../components/companyArea/shop/CompanyShop.vue';
import Cart from '../components/sharedComponents/Cart.vue';
import Budgets from '../components/companyArea/Settings/Budgets.vue';
import CompanyLocale from '../components/companyArea/Settings/CompanyLocale.vue';
import PauseAccount from '../components/companyArea/Settings/PauseAccount.vue';
import CompanyProfile from '../components/companyArea/Achievements/CompanyProfile.vue';
import LinkPartnership from '../components/companyArea/Achievements/LinkPartnership.vue';
import ExperienceReport from '../components/companyArea/Achievements/ExperienceReport.vue';

export default [
// Leads
  { path: '/companies/:company_id/:lead_uuid?', component: PendingLeads, name: 'PendingLeads', meta: { area: 'Company', menu: 'menu.mainMenu.leads', requiresAuth: true } },
  { path: '/companies/:company_id/new-leads/:lead_uuid?', component: NewLeads, name: 'NewLeads', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/confirmed-quotes', component: ConfirmedQuotes, name: 'ConfirmedQuotes', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/required-actions', component: RequiredActions, name: 'RequiredActions', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/archive', component: Archive, name: 'Archive', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/cart', component: Cart, name: 'CompanyCart', meta: { area: 'Company', requiresAuth: true } },
  {
    path: '/companies/:company_id/leads/:lead_uuid', component: LeadFocus, name: 'LeadFocus', meta: { area: 'Company', mobileButtonMenu: true, requiresAuth: true },
    children: [
      { path: 'chat', component: Chat, name: 'CompanyChat', meta: { area: 'Company', requiresAuth: true } },
      { path: 'note', component: CompanyNote, name: 'CompanyNote', meta: { area: 'Company', requiresAuth: true } },
      { path: 'quote', component: CompanyQuote, name: 'CompanyQuote', meta: { area: 'Company', requiresAuth: true } },
      { path: 'lead-menu', component: LeadMenu, name: 'LeadMenu', meta: { area: 'Company', requiresAuth: true } },
    ],
  },
// Accounting
  { path: '/accounting-pseudo-path', name: 'Accounting', meta: { area: 'Company', menu: 'menu.mainMenu.credit' } },
  { path: '/companies/:company_id/credit', component: Credit, name: 'Credit', meta: { area: 'Company', menu: 'menu.mainMenu.rechargeCredit', prepaid: true, childOf: 'Accounting', requiresAuth: true } },
  { path: '/companies/:company_id/invoices', component: Invoices, name: 'Invoices', meta: { area: 'Company', menu: 'menu.mainMenu.invoices', prepaid: false, childOf: 'Accounting', requiresAuth: true } },
  { path: '/companies/:company_id/transactions', component: Transactions, name: 'Transactions', meta: { area: 'Company', menu: 'menu.mainMenu.creditHistory', childOf: 'Accounting', requiresAuth: true } },
  { path: '/companies/:company_id/reports', component: Reports, name: 'Reports', meta: { area: 'Company', menu: 'menu.mainMenu.reports', childOf: 'Accounting', requiresAuth: true } },
// Settings
  { path: '/settings-pseudo-path', name: 'Settings', meta: { area: 'Company', menu: 'menu.mainMenu.settings' } },
  { path: '/companies/:company_id/shop', name: 'CompanyShop', component: CompanyShop, meta: { area: 'Company', menu: 'menu.mainMenu.boxes' } },
  { path: '/companies/:company_id/pause-account', component: PauseAccount, name: 'PauseAccount', meta: { area: 'Company', menu: 'menu.mainMenu.pauseAccount', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/classifications', component: Classifications, name: 'Classifications', meta: { area: 'Company', menu: 'menu.mainMenu.classifications', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/area', component: Area, name: 'Area', meta: { area: 'Company', menu: 'menu.mainMenu.area', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/closed-days', component: ClosedDays, name: 'ClosedDays', meta: { area: 'Company', menu: 'menu.mainMenu.closedDays', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/employees', component: Employees, name: 'Employees', meta: { area: 'Company', menu: 'menu.mainMenu.users', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/budgets', component: Budgets, name: 'Budgets', meta: { area: 'Company', menu: 'menu.mainMenu.budgets', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/locale', component: CompanyLocale, name: 'CompanyLocale', meta: { area: 'Company', menu: 'menu.mainMenu.locale', childOf: 'Settings', requiresAuth: true } },
  // Achievements
  { path: '/achievements-pseudo-path', name: 'Company', meta: { area: 'Company', menu: 'menu.mainMenu.company' } },
  { path: '/companies/:company_id/reviews', component: CompanyReviews, name: 'CompanyReviews', meta: { area: 'Company', menu: 'menu.mainMenu.reviews', childOf: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/partner-status', component: PartnerStatus, name: 'PartnerStatus', meta: { area: 'Company', menu: 'menu.mainMenu.partnerStatus', childOf: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/company-profile', component: CompanyProfile, name: 'CompanyProfile', meta: { area: 'Company', menu: 'menu.mainMenu.companyProfile', childOf: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/link-partnership', component: LinkPartnership, name: 'LinkPartnership', meta: { area: 'Company', menu: 'menu.mainMenu.linkPartnership', childOf: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/contact', component: Contact, name: 'Contact', meta: { area: 'Company', menu: 'menu.mainMenu.contact', childOf: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/experience-report', component: ExperienceReport, name: 'ExperienceReport', meta: { area: 'Company', menu: 'menu.mainMenu.experienceReport', childOf: 'Company', requiresAuth: true } },
  // { path: '/companies/:company_id/statistics', component: CompanyStatistics, name: 'CompanyStatistics', meta: { area: 'Company', menu: 'Statistiken', childOf: 'Achievements', requiresAuth: true } },
];
