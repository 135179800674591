<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" data-cy="header-title">{{ $t('companyArea.accounting.credit.saldo') }}</p>
    </template>

    <CompanyBalance :company="company" :user="user" @charge-with-main-card="chargeWithMainCard" />
    <!--    <PostfinancePanel ref="postfinancePanel" :company="company" :user="user" />-->
    <StripePanel v-if="showStripePanel" ref="stripePanel" :company="company" />
    <DatatransPanel v-if="showDatatransPanel" ref="datatransPanel" :company="company" />
    <AutomaticRefill :company="company" />
    <BankAccountInfo :company="company" />
  </layout>
</template>

<script>
  import store from '../../store/mainStore';
  import Layout from '../../Layout.vue';
  import notification from '../../connections/notification';
  import CompanyBalance from './credit/CompanyBalance.vue';
  import StripePanel from './credit/StripePanel.vue';
  import PostfinancePanel from './credit/PostfinancePanel.vue';
  import BankAccountInfo from './credit/BankAccountInfo.vue';
  import AutomaticRefill from './credit/AutomaticRefill.vue';
  import DatatransPanel from './credit/DatatransPanel.vue';

  export default {
    name: 'Credit',
    components: { DatatransPanel, AutomaticRefill, BankAccountInfo, StripePanel, CompanyBalance, Layout },
    data() {
      return {
        user: store.state.main.user,
        showStripeForm: false,
        showPostfinanceForm: false,
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      stripePaymentMethods() {
        return this.company.stripe_payment_methods;
      },
      showStripePanel() {
        // $route.query.payment_intent is important for the case if the user has no stripePaymentMethods
        // and is in the process of adding one (with ?showStripe=true)
        return true; // this.stripePaymentMethods.length > 0 || this.$route.query.showStripe || this.$route.query.payment_intent;
      },
      showDatatransPanel() {
        return this.$route.query.show_datatrans || this.$route.query.datatrans;
      },
    },
    methods: {
      chargeWithMainCard() {
        // if (this.company.postfinance_tokens.length > 0 && (this.company.postfinance_tokens.find(obj => obj.main == true))) {
        //   this.$refs.postfinancePanel.openFormWithMainCard();
        if (this.company.stripe_payment_methods.length > 0 && (this.company.stripe_payment_methods.find(obj => obj.main == true))) {
          this.$refs.stripePanel.openFormWithMainCard();
        } else {
          notification.warning(this.$t('companyArea.accounting.credit.noCardFound'));
        }
      },
    },
  };
</script>

