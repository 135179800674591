<template>
  <lead-filter-assistant v-if="assistant"
                         :form="form"
                         :lead-filter="leadFilter"
                         :formdata="formdata"
                         @sync-filter="syncLeadFilterField"
                         @store-filter="storeLeadFilterField"
                         @delete-filter="deleteLeadFilter"
                         @close-assistant="$emit('update:assistant', false)"
                         @update-lead-filter="updateLeadFilter"
  />
  <div v-else>
    <div v-if="!leadFilter.hasOwnProperty('id')" class="text-center">
      <p v-html="$t('companyArea.settings.snippets.leadFilters.directBuyDisabledInfo', {name: form.name})" />
      <div class="flex justify-center mt-5">
        <button class="btn btn-primary btn-lg" @click="storeLeadFilter">
          {{ $t('companyArea.settings.snippets.leadFilters.enableDirectBuy') }}
        </button>
      </div>
      <p class="text-sm mt-5" v-html="$t('companyArea.settings.snippets.leadFilters.enableDirectBuyInfo')" />
    </div>
    <div v-else>
      <p class="mt-7">
        {{ $t('companyArea.settings.snippets.leadFilters.closedCalenderInfo1') }}
        <router-link :to="{ name: 'ClosedDays' }" class="font-semibold">
          {{ $t('companyArea.settings.snippets.leadFilters.closedCalenderInfo2') }}
        </router-link>
        {{ $t('companyArea.settings.snippets.leadFilters.closedCalenderInfo3') }}
      </p>
      <p class="text-center text-lg font-semibold mt-8">{{ $t('classification.' + form.translation_key) }}</p>
      <div class="relative mt-2">
        <div class="absolute hidden md:block h-full w-1/2 bg-gray-50" />
        <div class="relative flex mt-2 p-2">
          <div class="md:w-1/2 text-xs pr-2">
            <span class="text-lg">{{ $t('companyArea.settings.snippets.leadFilters.filterOptions') }}</span>
          </div>
          <div v-if="leadFilterFieldsCount > 0" class="hidden md:block w-1/2 text-xs pl-2">
            <span class="text-lg">{{
              $t('companyArea.settings.snippets.leadFilters.filtersActive', { leadFilterFieldsCount: leadFilterFieldsCount })
            }}</span>
          </div>
          <div v-else class="hidden md:block w-1/2 text-xs pl-2">
            <span class="text-lg">{{
              $t('companyArea.settings.snippets.leadFilters.noActiveFilters')
            }}</span><br>{{ $t('companyArea.settings.snippets.leadFilters.noActiveFiltersInfo') }}
          </div>
        </div>
        <div class="relative flex flex-col md:flex-row">
          <div class="relative md:w-1/2 bg-gray-300 p-2">
            <circle-button :icon="['fas','question']"
                           size="xs"
                           class="absolute hidden md:flex top-1 right-2"
                           @click="show.distanceInfo = true"
            />
            <info-circle v-if="leadFilter.distance"
                         size="xs"
                         color="border-black bg-white"
                         class="absolute md:hidden top-2 right-2"
                         @click="show.distanceMobile = ! show.distanceMobile"
            >
              <font-awesome-icon :icon="['fal','filter']" />
            </info-circle>
            {{ $t('companyArea.settings.snippets.leadFilters.excludeLongDistanceJobs') }}
            <div class="relative mt-4">
              <input v-model="formdata.distance"
                     type="number"
                     class="pr-12 w-full border border-gray-400"
                     @keyup.enter="updateLeadFilter"
              >

              <div class="absolute right-2 top-1.5 text-xxs z-10">
                km
              </div>
            </div>

            <div class="flex">
              <button :disabled="formdata.distance === leadFilter.distance || formdata.distance === 0"
                      class="btn btn-primary btn-sm mt-2"
                      @click="updateLeadFilter"
              >
                {{ $t('companyArea.settings.snippets.leadFilters.activate') }}
              </button>
            </div>
          </div>
          <div v-if="leadFilter.distance"
               class="md:block md:w-1/2 bg-green-200 text-center py-2 px-4"
               :class="[show.distanceMobile ? 'block' : 'hidden']"
          >
            <p v-html="$t('companyArea.settings.snippets.leadFilters.jobLocationDistance', {distance: leadFilter.distance})" />
            <div>
              <button class="btn btn-primary btn-sm mt-2"
                      @click="formdata.distance = null; updateLeadFilter()"
              >
                {{ $t('companyArea.settings.snippets.leadFilters.removeFilter') }}
              </button>
            </div>
          </div>
        </div>

        <div class="relative flex flex-col md:flex-row mt-2">
          <div class="relative md:w-1/2 bg-gray-300 p-2">
            <circle-button :icon="['fas','question']"
                           size="xs"
                           class="absolute hidden md:flex top-1 right-2"
                           @click="show.shortTermInfo = true"
            />
            <info-circle v-if="leadFilter.short_term"
                         size="xs"
                         color="border-black bg-white"
                         class="absolute md:hidden top-2 right-2"
                         @click="show.shortTermMobile = ! show.shortTermMobile"
            >
              <font-awesome-icon :icon="['fal','filter']" />
            </info-circle>
            {{ $t('companyArea.settings.snippets.leadFilters.excludeShortTermQuotes') }}
            <div class="relative mt-4">
              <input v-model="formdata.short_term"
                     type="number"
                     class="pr-12 w-full border border-gray-400"
                     @keyup.enter="updateLeadFilter"
              >

              <div class="absolute right-2 top-1.5 text-xxs z-10">
                {{ $t('companyArea.settings.snippets.leadFilters.days') }}
              </div>
            </div>
            <div class="flex ">
              <button :disabled="formdata.short_term === leadFilter.short_term || formdata.short_term === 0"
                      class="btn btn-primary btn-sm mt-2"
                      @click="updateLeadFilter"
              >
                {{ leadFilter.short_term > 0 ? $t('general.change') : $t('general.activate') }}
              </button>
            </div>
          </div>
          <div v-if="leadFilter.short_term"
               class="md:block md:w-1/2 bg-red-100 text-center py-2 px-4"
               :class="[show.shortTermMobile ? 'block' : 'hidden']"
          >
            <p v-html="$t('companyArea.settings.snippets.leadFilters.excludeShortTermQuotesInfo', {short_term: leadFilter.short_term})" />
            <div>
              <button class="btn btn-primary btn-sm mt-2"
                      @click="formdata.short_term = null; updateLeadFilter()"
              >
                {{ $t('companyArea.settings.snippets.leadFilters.removeFilter') }}
              </button>
            </div>
          </div>
        </div>

        <div class="relative flex flex-col md:flex-row mt-2">
          <div class="relative md:w-1/2 bg-gray-300 p-2">
            <circle-button :icon="['fas','question']"
                           size="xs"
                           class="absolute hidden md:flex top-1 right-2"
                           @click="show.longTermInfo = true"
            />
            <info-circle v-if="leadFilter.long_term"
                         size="xs"
                         color="border-black bg-white"
                         class="absolute md:hidden top-2 right-2"
                         @click="show.longTermMobile = ! show.longTermMobile"
            >
              <font-awesome-icon :icon="['fal','filter']" />
            </info-circle>
            {{ $t('companyArea.settings.snippets.leadFilters.excludeLongTermQuotes') }}
            <div class="relative mt-4">
              <input v-model="formdata.long_term"
                     type="number"
                     class="pr-12 w-full border border-gray-400"
                     @keyup.enter="updateLeadFilter"
              >

              <div class="absolute right-2 top-1.5 text-xxs z-10">
                {{ $t('companyArea.settings.snippets.leadFilters.months') }}
              </div>
            </div>
            <div class="flex ">
              <button :disabled="formdata.long_term === leadFilter.long_term || formdata.long_term === 0"
                      class="btn btn-primary btn-sm mt-2"
                      @click="updateLeadFilter"
              >
                {{ $t('general.activate') }}
              </button>
            </div>
          </div>
          <div v-if="leadFilter.long_term"
               class="md:block md:w-1/2 bg-green-200 text-center py-2 px-4"
               :class="[show.longTermMobile ? 'block' : 'hidden']"
          >
            <p v-html="$t('companyArea.settings.snippets.leadFilters.excludeLongTermQuotesInfo', {long_term: leadFilter.long_term})" />
            <div>
              <button class="btn btn-primary btn-sm mt-2"
                      @click="formdata.long_term = null; updateLeadFilter()"
              >
                {{ $t('companyArea.settings.snippets.leadFilters.removeFilter') }}
              </button>
            </div>
          </div>
        </div>


        <div class="relative flex flex-col md:flex-row mt-2">
          <div class="relative md:w-1/2 bg-gray-300 p-2">
            <info-circle v-if="leadFilter.phone"
                         size="xs"
                         color="border-black bg-white"
                         class="absolute md:hidden top-2 right-2"
                         @click="show.phoneMobile = ! show.phoneMobile"
            >
              <font-awesome-icon :icon="['fal','filter']" />
            </info-circle>
            {{ $t('companyArea.settings.snippets.leadFilters.excludeNoPhone') }}
            <div class="flex ">
              <button :disabled="formdata.phone === 1"
                      class="btn btn-primary btn-sm mt-2"
                      @click="formdata.phone = 1; updateLeadFilter()"
              >
                {{ $t('general.activate') }}
              </button>
            </div>
          </div>
          <div v-if="leadFilter.phone"
               class="md:block md:w-1/2 bg-green-200 text-center py-2 px-4"
               :class="[show.phoneMobile ? 'block' : 'hidden']"
          >
            {{ $t('companyArea.settings.snippets.leadFilters.excludeNoPhoneInfo') }}
            <div>
              <button class="btn btn-primary btn-sm mt-2"
                      @click="formdata.phone = null; updateLeadFilter()"
              >
                {{ $t('companyArea.settings.snippets.leadFilters.removeFilter') }}
              </button>
            </div>
          </div>
        </div>


        <div class="relative flex flex-col md:flex-row mt-2">
          <div class="relative md:w-1/2 bg-gray-300 p-2">
            <circle-button :icon="['fas','question']"
                           size="xs"
                           class="absolute hidden md:flex top-1 right-2"
                           @click="show.priceQualityInfo = true"
            />
            <info-circle v-if="leadFilter.price_quality"
                         size="xs"
                         color="border-black bg-white"
                         class="absolute md:hidden top-2 right-2"
                         @click="show.priceQualityMobile = ! show.priceQualityMobile"
            >
              <font-awesome-icon :icon="['fal','filter']" />
            </info-circle>
            {{ $t('companyArea.settings.snippets.leadFilters.excludeCheapPrice') }}
            <div class="pt-3">
              <input v-model="formdata.price_quality"
                     max="1"
                     min="0"
                     step="0.1"
                     type="range"
                     class="rounded-lg appearance-none bg-white h-2 w-full mt-5"
              >
              <div class="flex justify-between">
                <div class="font-semibold">
                  {{
                    $t('companyArea.settings.snippets.leadFilters.price')
                  }}
                </div>
                <div class="font-semibold">
                  {{
                    $t('companyArea.settings.snippets.leadFilters.quality')
                  }}
                </div>
              </div>
            </div>
            <div class="flex ">
              <button :disabled="formdata.price_quality === leadFilter.price_quality"
                      class="btn btn-primary btn-sm mt-2"
                      @click="updateLeadFilter"
              >
                {{ $t('general.activate') }}
              </button>
            </div>
          </div>
          <div v-if="leadFilter.price_quality"
               class="md:block md:w-1/2 bg-red-100 text-center py-2 px-4"
               :class="[show.priceQualityMobile ? 'block' : 'hidden']"
          >
            <span v-if="leadFilter.price_quality === 0"
                  v-html="$t('companyArea.settings.snippets.leadFilters.excludeCheapPriceInfo1')"
            />
            <span v-else
                  v-html="$t('companyArea.settings.snippets.leadFilters.excludeCheapPriceInfo2', { percent: Math.round((1 - leadFilter.price_quality) * 100)})"
            />
            <div>
              <button class="btn btn-primary btn-sm mt-2"
                      @click="formdata.price_quality = null; updateLeadFilter()"
              >
                {{ $t('companyArea.settings.snippets.leadFilters.removeFilter') }}
              </button>
            </div>
          </div>
        </div>


        <div class="relative flex mt-6 -mb-2">
          <div class="w-1/2 pr-2">
            <span class="text-base font-semibold">{{
              $t('companyArea.settings.snippets.leadFilters.formFields')
            }}</span>
          </div>
        </div>
        <div v-if="inputFields.length > 0">
          <component :is="field.type"
                     v-for="field in inputFields"
                     :key="field.id"
                     v-model:formdata="formdata"
                     class="relative"
                     :field="field"
                     :filters="leadFilter.lead_filter_fields"
                     :option-fields="field.type === 'selectField' || field.type === 'radioField' ? form.fields : []"
                     @storeFilter="storeLeadFilterField"
                     @syncFilter="syncLeadFilterField"
                     @deleteFilter="deleteLeadFilter"
          />
        </div>
      </div>
    </div>
  </div>

  <confirmation-modal v-if="show.deleteFilter"
                      confirmation="Möchten Sie die aktuellen Filtereinstellungen löschen?"
                      @close="show.deleteFilter = false"
                      @action="deleteLeadFilter"
  />

  <confirmation-modal v-if="show.distanceInfo" @close="show.distanceInfo = false">
    <p class="text-lg">{{ $t('companyArea.settings.snippets.leadFilters.radiusFilterTitle') }}</p>
    <p class="mt-3">
      {{ $t('companyArea.settings.snippets.leadFilters.radiusFilterText1') }}<br><br>
      {{ $t('companyArea.settings.snippets.leadFilters.radiusFilterText2') }}
    </p>
  </confirmation-modal>
  <confirmation-modal v-if="show.shortTermInfo" @close="show.shortTermInfo = false">
    <p class="text-lg">{{ $t('companyArea.settings.snippets.leadFilters.shortTermTitle') }}</p>
    <p class="mt-3">
      {{ $t('companyArea.settings.snippets.leadFilters.shortTermText1') }}<br><br>
      {{ $t('companyArea.settings.snippets.leadFilters.shortTermText2') }}
    </p>
  </confirmation-modal>
  <confirmation-modal v-if="show.longTermInfo" @close="show.longTermInfo = false">
    <p class="text-lg">{{ $t('companyArea.settings.snippets.leadFilters.longTermTitle') }}</p>
    <p class="mt-3">
      {{ $t('companyArea.settings.snippets.leadFilters.longTermText1') }}<br><br>
      {{ $t('companyArea.settings.snippets.leadFilters.longTermText2') }}
    </p>
  </confirmation-modal>

  <confirmation-modal v-if="show.priceQualityInfo" @close="show.priceQualityInfo = false">
    <p class="text-lg">{{ $t('companyArea.settings.snippets.leadFilters.priceSensitiveTitle') }}</p>
    <p class="mt-3">
      {{ $t('companyArea.settings.snippets.leadFilters.priceSensitiveText1') }}<br><br>
      <span v-html="$t('companyArea.settings.snippets.leadFilters.priceSensitiveText2')" />
    </p>
  </confirmation-modal>
</template>

<script>
  import companyApi from '../../../connections/company';
  import CheckboxField from './checkboxField.vue';
  import SelectField from './selectField.vue';
  import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
  import LeadFilterAssistant from './LeadFilterAssistant.vue';
  import InfoCircle from '../../../snippets/InfoCircle.vue';
  import CircleButton from '../../../snippets/CircleButton.vue';

  export default {
    name: 'LeadFilters',
    components: { CircleButton, InfoCircle, LeadFilterAssistant, ConfirmationModal, CheckboxField, SelectField },
    props: {
      leadFilter: Object,
      form: Object,
      assistant: Boolean,
    },
    emits: ['close', 'update:leadFilter', 'update:assistant'],
    data() {
      return {
        formdata: {
          dynamic_form: {},
          field_id: '',
          include: [],
          leadFilterId: '',
          form_id: this.form.id,
          distance: '',
          short_term: '',
          long_term: '',
          newDistance: '',
          phone: '',
          desired_quotes: '',
          price_quality: '',
        },
        leadFilters: [],
        show: {
          deleteFilter: false,
          distanceInfo: false,
          shortTermInfo: false,
          longTermInfo: false,
          priceQualityInfo: false,
          distanceMobile: false,
          shortTermMobile: false,
          longTermMobile: false,
          phoneMobile: false,
          priceQualityMobile: false,
        },

      };
    },
    computed: {
      inputFields() {
        if (this.form.hasOwnProperty('fields')) {
          return this.form.fields.filter(obj => obj.parent === null && obj.lead_filter && (obj.type === 'checkboxField' || obj.type === 'selectField'));
        } else {
          return [];
        }
      },
      leadFilterFieldsCount() {
        let count = 0;
        if (this.leadFilter.short_term) count++;
        if (this.leadFilter.long_term) count++;
        if (this.leadFilter.phone) count++;
        if (this.leadFilter.price_quality) count++;
        if (this.leadFilter.desired_quotes) count++;
        if (this.leadFilter.hasOwnProperty('lead_filter_fields')) {
          // grouping function
          let singleFilters = [];
          this.leadFilter.lead_filter_fields.forEach(obj => singleFilters.includes(obj.field_id) ? '' : singleFilters.push(obj.field_id));
          count += singleFilters.length;
        }
        return count;
      },
    },
    watch: {
      leadFilter: {
        immediate: true,
        handler() {
          if (this.leadFilter.hasOwnProperty('id')) {
            this.formdata.leadFilterId = this.leadFilter.id;
            this.formdata.distance = this.leadFilter.distance;
            this.formdata.short_term = this.leadFilter.short_term;
            this.formdata.long_term = this.leadFilter.long_term;
            this.formdata.phone = this.leadFilter.phone;
            this.formdata.price_quality = this.leadFilter.price_quality;
            this.formdata.desired_quotes = this.leadFilter.desired_quotes;
            if (!this.leadFilter.hasOwnProperty('lead_filter_fields')) {
              this.leadFilter.lead_filter_fields = [];
            }
          } else {
            this.formdata.distance = '';
            this.formdata.short_term = '';
            this.formdata.long_term = '';
            this.formdata.phone = '';
            this.formdata.price_quality = '';
            this.formdata.desired_quotes = '';
          }
        }, deep: true,
      },
    },
    methods: {
      storeLeadFilterField() {
        console.log('store')
        companyApi.post('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/fields', this.formdata, {
          notification: true,
          progress: true,
        }).then(response => {
          if(response.data.notificationType === 'success') {
            const leadFilter = this.leadFilter;
            leadFilter.lead_filter_fields.push(response.data.content.leadFilterField);
            console.log(leadFilter)
            this.$emit('update:leadFilter', leadFilter)
            this.formdata.include = []
          }
        });
      },
      syncLeadFilterField() {
        console.log('sync')
        companyApi.patch('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/fields', this.formdata, {
          notification: true,
          progress: true,
        }).then(response => {
          if(response.data.notificationType === 'success') {
            const leadFilter = this.leadFilter;
            leadFilter.lead_filter_fields = response.data.content.leadFilterFields;
            this.$emit('update:leadFilter', leadFilter)
            this.formdata.include = []
          }
        });
      },
      updateLeadFilter() {
        this.formdata.distance = Math.floor(this.formdata.distance);
        this.formdata.short_term = Math.floor(this.formdata.short_term);
        this.formdata.long_term = Math.floor(this.formdata.long_term);
        companyApi.patch('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id, this.formdata, {
          notification: true,
          progress: true,
        }).then(response => response.data.notificationType === 'success' ?
          [this.$emit('update:leadFilter', response.data.content.leadFilter)] : '');
      },
      deleteLeadFilter(leadFilterField) {
        companyApi.delete('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/lead-filter-fields/' + leadFilterField.id,
          {
            notification: true,
            progress: true,
          }).then(response => {
          const leadFilter = this.leadFilter;
          leadFilter.lead_filter_fields = leadFilter.lead_filter_fields.filter(obj => obj.field_id !== leadFilterField.field_id);
          this.$emit('update:leadFilter', leadFilter);
        });
      },
    },
  updateLeadFilterFields() {

    },
  };

</script>

<style scoped>

</style>
